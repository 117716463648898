import { REMOVE_USER, SAVE_USER, SET_AUTH } from "./actionType";

const initialState = {
  auth: {},
  role: [],
  isAuthenticated: false,
};

const authRedux = (state = initialState, action) => {
  switch (action?.type) {
    case SAVE_USER:
      return {
        ...state,
        auth: action?.payload,
        role: action?.role,
        isAuthenticated: action?.isAuth,
      };
    case REMOVE_USER:
      return {
        auth: {},
        role: [],
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export default authRedux;
