import React, { useState } from "react";
import "./layout.scss";
import { Layout, Menu } from "antd";
import CustomHeader from "../dashboard/header/Header";
import CustomContent from "../dashboard/content/Content";
import { useHistory, useLocation } from "react-router-dom";
import { routes } from "../../../routes/routes";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const { Sider } = Layout;
const { SubMenu } = Menu;

const CustomLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(true);
  const history = useHistory();
  const path = useLocation()?.pathname;
  const auth = useSelector((state) => state.authRedux);
  const {t} = useTranslation();

  return (
    <>
      <Layout style={{ height: "100vh" }}>
        <Sider  trigger={null} collapsible collapsed={collapsed}>
          <div className="logo">
            <h3>FTTI</h3>
          </div>
          <Menu theme="dark" mode="inline" defaultSelectedKeys={[path]}>
            {routes?.map((item, index) => {
              if (item?.config?.showLink) {
                if (
                  item?.config.key.includes("*") ||
                  item?.config?.key?.includes(auth?.role[0])
                ) {
                  return (
                    <>
                      <Menu.Item
                        key={item?.path}
                        onClick={() => history.push(item?.path)}
                        icon={item?.icon && item?.icon}
                      >
                        {t(item?.name)}
                      </Menu.Item>                      
                    </>
                  );
                }
              }
            })}
          </Menu>
        </Sider>

        <Layout className="site-layout">
          <CustomHeader setCollapsed={setCollapsed} collapsed={collapsed} />
          <CustomContent>{children}</CustomContent>
        </Layout>
      </Layout>
    </>
  );
};

export default CustomLayout;
