import {
  Button,
  Col,
  Form,
  Input,
  message,
  notification,
  Row,
  Spin,
} from "antd";
import React from "react";
import { useState, Fragment } from "react";
import instance from "../../api/axios";
import "./loginsetting.scss";
import { useHistory } from "react-router-dom";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const LoginSetting = () => {
  //hooks
  const history = useHistory();
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const { t } = useTranslation();

  const onFinish = async (values) => {
    let data = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      if (value || key == "status") {
        if (key == "status") {
          data.append(key, value ? 1 : 0);
        } else {
          data.append(key, value);
        }
      }
    });
    try {
      setLoader(true);
      const resp = await instance({
        method: "post",
        url: "/edit/user/settings",
        data: data,
      });
      setLoader(false);
      history.push("/applicantui");
      if (resp?.status == 200) {
        notification["success"]({
          message: "Sizning login parolingiz yangilandi!",
          placement: "topRight",
          duration: 2,
        });
      }
    } catch (error) {
      setLoader(false);
      form.setFields([
        {
          name: ["password"],
          errors: [...error?.response.data?.errors?.password],
        },
      ]);
      notification["error"]({
        message: "Login parolni o`zgartirishda xatolik",
        placement: "topRight",
        duration: 2,
      });
    }
  };

  return (
    <Fragment>
      <Spin spinning={loader}>
        <div className="edit-user-wrapper">
          <h3 className="text-center title">{t("Parolni o'zgartirish")}</h3>
          <Form
            name="basic"
            form={form}
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Row gutter={[8, 8]}>
              {/* <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <Form.Item
                  rules={[
                    { required: false, message: "Please input field value!" },
                  ]}
                  name={"username"}
                  label={"Foydalanuvchi nomi(yangisi)"}
                >
                  <Input placeholder="login"></Input>
                </Form.Item>
              </Col> */}

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <Form.Item
                  rules={[
                    { required: false, message: "Please input field value!" },
                  ]}
                  name={"password"}
                  label={t("Yangi parol")}
                >
                  <Input.Password
                    type={"password"}
                    placeholder="password"
                    iconRender={(visible) =>
                      visible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />
                    }
                  ></Input.Password>
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <Form.Item
                  rules={[
                    { required: false, message: "Please input field value!" },
                  ]}
                  name={"confirm-password"}
                  label={t("Parolni qayta yozish")}
                >
                  <Input.Password
                    type={"password"}
                    placeholder="password"
                    iconRender={(visible) =>
                      visible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />
                    }
                  ></Input.Password>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col
                span={24}
                style={{
                  textAlign: "right",
                }}
              >
                <Button
                  htmlType="reset"
                  className="m-1 btn-danger"
                  type="primary"
                >
                  {t("O'chirish")}
                </Button>
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  htmlType="submit"
                  className="m-1"
                  type="primary"
                >
                  {t("Saqlash")}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Spin>
    </Fragment>
  );
};

export default LoginSetting;
