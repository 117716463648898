import React from "react";
import { Fragment, useState, useEffect } from "react";
import { Button, Col, Form, Input, message, Row, Spin, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import instance from "../../api/axios";
import SunEditor from "suneditor-react";
const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

const Requirements = () => {
  const [loader, setLoader] = useState(false);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [content, setContent] = useState();

  const onFinish = async (values) => {
    values = {
      ...values,
      status: values?.status ? 1 : 0,
    };

    try {
      setLoader(true);
      const resp = await instance({
        method: "post",
        url: "/admission-requirements",
        data: values,
      });
      setLoader(false);
    } catch (error) {
      message.error("Ma'lumotni saqlashda xatolik!");
      setLoader(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: "/admission-requirements",
        });
        if (resp?.status === 200) {
          form.setFieldsValue({
            ...resp?.data["data"],
            status: parseInt(resp?.data["data"]),
          });
          setContent({
            content_uz: resp?.data?.data?.content_uz,
            content_ru: resp?.data?.data?.content_ru,
          });
        }
      } catch (error) {
        message.error("Ma'lumotlarni ochishda xatolik!");
      }
    })();
  }, []);

  return (
    <Fragment>
      <Spin spinning={loader}>
        <Form
          name="basic"
          form={form}
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Row className="add-edu-type" gutter={[16, 16]}>
            <Col
              span={24}
              className="d-flex align-content-center justify-content-between"
            >
              <h3>{t("Hujjat topshirish qoidalari")}</h3>
              <div>
                <Button
                  htmlType="reset"
                  className="btn-danger mx-3"
                  type="primary"
                >
                  {t("O'chirish")}
                </Button>
                <Button htmlType="submit" type="primary">
                  {t("Saqlash")}
                </Button>
              </div>
            </Col>
            <Col span={24}>
              <Form.Item
                rules={[
                  { required: true, message: "Iltimos maydonlarni to'ldiring" },
                ]}
                name="title_uz"
                label={t("Nomi uz")}
              >
                <Input placeholder={t("o'zbekcha nomi")}></Input>
              </Form.Item>
            </Col>{" "}
            <Col span={24}>
              <Form.Item
                rules={[
                  { required: true, message: "Iltimos maydonlarni to'ldiring" },
                ]}
                name="title_ru"
                label={t("Nomi ru")}
              >
                <Input placeholder={t("ruscha nomi")}></Input>
              </Form.Item>
            </Col>{" "}
            <Col span={24}>
              <Tabs onChange={callback} type="card">
                <TabPane tab="uz" key="1">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Iltimos maydonlarni to'ldiring",
                      },
                    ]}
                    name="content_uz"
                    label={t("Kontent uz")}
                  >
                    <SunEditor
                      height="100%"
                      setContents={content ? content?.content_uz : ""}
                    />
                  </Form.Item>
                </TabPane>

                <TabPane tab="ru" key="2">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Iltimos maydonlarni to'ldiring",
                      },
                    ]}
                    name="content_ru"
                    label={t("Kontent ru")}
                  >
                    <SunEditor
                      height="100%"
                      setContents={content ? content?.content_ru : ""}
                    />
                  </Form.Item>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Fragment>
  );
};

export default Requirements;
