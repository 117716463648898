import React from "react";
import { Fragment } from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { FaEye, FaEdit, FaTrashAlt } from "react-icons/fa";
import { Button, Col, message, Popconfirm, Row, Switch, Tag } from "antd";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import instance from "../../api/axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Faculties = () => {
  // hooks
  const [faculties, setFaculties] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { t } = useTranslation();

  // popconfirm
  const [visible, setVisible] = React.useState("");
  const [confirmLoading, setConfirmLoading] = React.useState(false);

  const showPopconfirm = (id) => {
    setVisible(id);
  };

  const handleCancel = () => {
    setVisible("");
  };

  //   delete faculty
  const delete_faculty = async (id) => {
    try {
      const resp = await instance({
        method: "delete",
        url: `/faculties/${id}`,
      });
      setRefresh(!refresh);
      setVisible("");
    } catch (error) {
      message.error("Ma`lumotni o`chirishda xatolik!");
      setVisible("");
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: "/faculties?expand=education_types,education_languages,admission_type",
        });

        setFaculties(resp?.data?.faculties);
      } catch (error) {
        message.error("error in getting faculties");
      }
    })();
  }, [refresh]);

  return (
    <Fragment>
      <Row>
        <Col
          className="d-flex align-content-center justify-content-between"
          span={24}
        >
          <h3>{t("Ta`lim yo`nalishlari")}</h3>
          <Button type="primary">
            <NavLink to={"/create_faculty"}>{t("Qo'shish")} +</NavLink>
          </Button>
        </Col>
        <Table className="table table-responsive table-bordered table-striped table-hover text-center">
          <Thead className="table-head">
            <Tr>
              <Th>№</Th>
              <Th>{t("Nomi uz")}</Th>
              <Th>{t("Nomi ru")}</Th>
              <Th>{t("Ta'lim shakli")}</Th>
              <Th>{t("Ta'lim tili")}</Th>
              <Th>{t("Qabul turi")}</Th>
              <Th>{t("Tavsif")}</Th>
              <Th>{t("Holati")}</Th>
              <Th>{t("Amallar")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {faculties?.map((v, i) => {
              return (
                <Tr>
                  <Td>{i + 1}</Td>
                  <Td>{v?.name_uz}</Td>
                  <Td>{v?.name_ru}</Td>
                  <Td>
                    {v?.education_types &&
                      v?.education_types?.map((item) => (
                        <Tag>
                          {[
                            i18next?.language === "uz"
                              ? item?.name_uz
                              : item?.name_ru,
                          ]}
                        </Tag>
                      ))}
                  </Td>
                  <Td>
                    {v?.education_languages &&
                      v?.education_languages?.map((item) => (
                        <Tag>
                          {[
                            i18next.language === "uz"
                              ? item?.name_uz
                              : item?.name_ru,
                          ]}
                        </Tag>
                      ))}
                  </Td>
                  <Td>
                    {[
                      i18next?.language === "uz"
                        ? v?.admission_type?.name_uz &&
                          v?.admission_type?.name_uz
                        : v?.admission_type?.name_ru &&
                          v?.admission_type?.name_ru,
                    ]}
                  </Td>
                  <Td>{v?.description}</Td>
                  <Td>
                    <Switch checked={v?.status === 1} />
                  </Td>
                  <Td>
                    <NavLink to={"/edit_faculty/" + v?.id} type="link">
                      <FaEdit />
                    </NavLink>
                    <Popconfirm
                      title={t("Ma'lumotni o'chirishni tasdiqlaysizmi?")}
                      okText={t("Ha")}
                      cancelText={t("Yo'q")}
                      visible={visible == v?.id}
                      onConfirm={() => delete_faculty(v?.id)}
                      okButtonProps={{
                        loading: confirmLoading,
                      }}
                      onCancel={handleCancel}
                    >
                      <Button
                        onClick={() => showPopconfirm(v.id)}
                        className="text-danger"
                        type="link"
                      >
                        <FaTrashAlt />
                      </Button>
                    </Popconfirm>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Row>
    </Fragment>
  );
};

export default Faculties;
