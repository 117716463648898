import {
  Button,
  Col,
  DatePicker,
  Divider,
  Empty,
  message,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Tabs,
} from "antd";
import React from "react";
import "./checker.scss";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { FaEye, FaDownload, FaTrashAlt, FaPrint } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import instance from "../../api/axios";
import { FILE_URL, ZIP_FILE_URL } from "../../api/constants";
import { Fragment } from "react";
import LoadExcelData from "../../services/download_excel";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";

const { TabPane } = Tabs;

const Checker = () => {
  // states
  const [applicants, setApplicants] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [meta, setMeta] = useState({ pageSize: 10, current: 1, total: 0 });
  const [statusTabActiveKey, setStatusTabActiveKey] = useState("0");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(0);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const { t } = useTranslation();
  const auth = useSelector((state) => state?.authRedux);
  const formatDate = "YYYY/MM/DD";
  const [refresh, setRefresh] = useState(false);
  // popconfirm
  const [visible, setVisible] = React.useState("false");
  const [confirmLoading, setConfirmLoading] = React.useState(false);

  const showPopconfirm = (id) => {
    setVisible(id);
  };

  const handleCancel = () => {
    setVisible("");
  };

  // delete applicants
  const delete_applicant = async (id) => {
    try {
      const resp = await instance({
        method: "post",
        url: `/application/delete/${id}`,
      });
      setRefresh(!refresh);
    } catch (error) {
      message.error("Ma`lumotni o`chirishda xatolik!");
      setVisible("");
    }
  };

  console.log("redux-------------------->", auth);
  // get applicants infos to table
  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: `/applications/${activeTabKey}/${statusTabActiveKey}?page=${currentPage}${
            perPage !== 0 ? "&page_size=" + perPage : ""
          }&expand=faculty,education_language,education_type`,
        });
        if (resp?.status === 200) {
          let data = resp?.data?.data;
          console.log(resp?.data?.data);
          setApplicants(data?.data);
          setMeta({
            pageSize: data?.per_page,
            current: data?.current_page,
            total: data?.total,
          });
        }
      } catch (e) {
        message.error("Error read applicants!");
      }
    })();
  }, [activeTabKey, statusTabActiveKey, currentPage, perPage, refresh]);

  const downloadFile = async (id) => {
    try {
      const resp = await instance({
        method: "get",
        headers: {
          responseType: "arraybuffer",
        },
        url: `applications-download-files/${id}`,
      });
      if (resp.status === 200) {
        const link = document.createElement("a");
        link.href = ZIP_FILE_URL + resp?.data?.path;
        link.click();
      }
    } catch (e) {
      message.error("Error download file!");
    }
  };
  //   JSX
  return (
    <Fragment>
      <Row>
        <Col className="" span={24}>
          <h3>{t("Hujjat topshiruvchilar")}</h3>
        </Col>

        <div className="d-flex align-items-end justify-content-between excel-export-wrapper w-100">
          <h5>{t("Hujjatlar hisoboti")}</h5>
          <div className="data-picker-wrapper">
            <DatePicker
              showToday={false}
              format={formatDate}
              onChange={(e, d) => setFrom(d)}
              renderExtraFooter={() => (
                <div className="text-center text-blue fw-bold">
                  "Boshlanish sanasi"
                </div>
              )}
            />
            <span> {t("dan")} </span>
            <DatePicker
              showToday={false}
              format={formatDate}
              onChange={(e, d) => setTo(d)}
              renderExtraFooter={() => (
                <div className="text-center text-blue fw-bold">
                  "Tugash sanasi"
                </div>
              )}
            />
            <span> {t("gacha")} </span>
            <LoadExcelData
              from={from}
              to={to}
              addmission_id={activeTabKey}
              status={statusTabActiveKey}
            />
          </div>
        </div>

        <Tabs
          activeKey={activeTabKey}
          onChange={(e) => {
            setActiveTabKey(`${e}`);
            setStatusTabActiveKey("0");
          }}
          style={{ width: "100%" }}
        >
          {["super-admin", "receiver_malaka_oshirish"].includes(
            auth?.role[0]
          ) ? (
            <TabPane
              tab={t("Malaka oshirish")}
              key="1"
              style={{ width: "100%" }}
            >
              {/* Export to excel */}
              {/* ["rv-m-o","s-a"].includes(auth?.role[0]) */}
              <Tabs
                activeKey={statusTabActiveKey}
                onChange={(e) => setStatusTabActiveKey(`${e}`)}
              >
                {
                  <TabPane
                    tab={t("Topshirilgan")}
                    key="0"
                    style={{ width: "100%" }}
                  >
                    {applicants?.length > 0 ? (
                      <Table className="table table-responsive table-bordered table-striped table-hover text-center">
                        <Thead className="table-head">
                          <Tr>
                            <Th>№</Th>
                            <Th>{t("FIO")}</Th>
                            <Th>{t("Tug'ilgan sana")}</Th>
                            <Th>{t("Manzil")}</Th>
                            <Th>{t("Ta`lim yo`nalishi")}</Th>
                            <Th>{t("Ta'lim tili")}</Th>
                            <Th>{t("Ta'lim shakli")}</Th>
                            <Th>{t("Amallar")}</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {applicants?.map((v, i) => {
                            return (
                              <Tr>
                                <Td>{i + 1}</Td>
                                <Td>
                                  {v?.name} {v?.surname} {v?.middle_name}
                                </Td>
                                <Td>{v?.birthdate}</Td>
                                <Td>{v?.address}</Td>
                                <Td>
                                  {v?.faculty
                                    ? v?.faculty[
                                        i18next.language === "uz"
                                          ? "name_uz"
                                          : "name_ru"
                                      ]
                                    : ""}
                                </Td>
                                <Td>
                                  {v?.education_language
                                    ? v?.education_language[
                                        i18next.language === "uz"
                                          ? "name_uz"
                                          : "name_ru"
                                      ]
                                    : ""}
                                </Td>
                                <Td>
                                  {v?.education_type
                                    ? v?.education_type[
                                        i18next.language === "uz"
                                          ? "name_uz"
                                          : "name_ru"
                                      ]
                                    : ""}
                                </Td>
                                <Td>
                                  <Button type="link">
                                    <NavLink to={"/applicant_viewer/" + v?.id}>
                                      <FaEye className="edit_button" />
                                    </NavLink>
                                  </Button>
                                  <Button
                                    type="link"
                                    onClick={() => downloadFile(v?.id)}
                                  >
                                    <FaDownload />
                                  </Button>
                                  {auth?.role?.includes("super-admin") ? (
                                    <Popconfirm
                                      title={t(
                                        "Ma'lumotni o'chirishni tasdiqlaysizmi?"
                                      )}
                                      okText={t("Ha")}
                                      cancelText={t("Yo'q")}
                                      visible={visible == v?.id}
                                      onConfirm={() => delete_applicant(v?.id)}
                                      okButtonProps={{
                                        loading: confirmLoading,
                                      }}
                                      onCancel={handleCancel}
                                    >
                                      <Button
                                        onClick={() => showPopconfirm(v.id)}
                                        className="text-danger"
                                        type="link"
                                      >
                                        <FaTrashAlt />
                                      </Button>
                                    </Popconfirm>
                                  ) : (
                                    ""
                                  )}
                                </Td>
                              </Tr>
                            );
                          })}
                        </Tbody>
                      </Table>
                    ) : (
                      <Empty />
                    )}
                    {applicants?.length > 0 && (
                      <Pagination
                        showSizeChanger
                        onChange={(c, s) => {
                          setCurrentPage(c);
                          setPerPage(s);
                        }}
                        onShowSizeChange={(c, s) => {
                          setCurrentPage(c);
                          setPerPage(s);
                        }}
                        current={meta?.current}
                        pageSize={meta?.pageSize}
                        total={meta?.total}
                      />
                    )}
                  </TabPane>
                }

                <TabPane
                  tab={t("Qaytarilgan")}
                  key="1"
                  style={{ width: "100%" }}
                >
                  {applicants?.length > 0 ? (
                    <Table className="table table-responsive table-bordered table-striped table-hover text-center">
                      <Thead className="table-head">
                        <Tr>
                          <Th>№</Th>
                          <Th>{t("FIO")}</Th>
                          <Th>{t("Tug'ilgan sana")}</Th>
                          <Th>{t("Manzil")}</Th>
                          <Th>{t("Ta`lim yo`nalishi")}</Th>
                          <Th>{t("Ta'lim tili")}</Th>
                          <Th>{t("Ta'lim shakli")}</Th>
                          <Th>{t("Amallar")}</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {applicants?.map((v, i) => {
                          return (
                            <Tr>
                              <Td>{i + 1}</Td>
                              <Td>
                                {v?.name} {v?.surname} {v?.middle_name}
                              </Td>
                              <Td>{v?.birthdate}</Td>
                              <Td>{v?.address}</Td>
                              <Td>
                                {v?.faculty
                                  ? v?.faculty[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                {v?.education_language
                                  ? v?.education_language[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                {v?.education_type
                                  ? v?.education_type[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                <Button type="link">
                                  <NavLink to={"/applicant_viewer/" + v?.id}>
                                    <FaEye className="edit_button" />
                                  </NavLink>
                                </Button>
                                <Button
                                  type="link"
                                  onClick={() => downloadFile(v?.id)}
                                >
                                  <FaDownload />
                                </Button>
                                {auth?.role?.includes("super-admin") ? (
                                  <Popconfirm
                                    title={t(
                                      "Ma'lumotni o'chirishni tasdiqlaysizmi?"
                                    )}
                                    okText={t("Ha")}
                                    cancelText={t("Yo'q")}
                                    visible={visible == v?.id}
                                    onConfirm={() => delete_applicant(v?.id)}
                                    okButtonProps={{
                                      loading: confirmLoading,
                                    }}
                                    onCancel={handleCancel}
                                  >
                                    <Button
                                      onClick={() => showPopconfirm(v.id)}
                                      className="text-danger"
                                      type="link"
                                    >
                                      <FaTrashAlt />
                                    </Button>
                                  </Popconfirm>
                                ) : (
                                  ""
                                )}
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  ) : (
                    <Empty />
                  )}
                  {applicants?.length > 0 && (
                    <Pagination
                      showSizeChanger
                      onChange={(c, s) => {
                        setCurrentPage(c);
                        setPerPage(s);
                      }}
                      onShowSizeChange={(c, s) => {
                        setCurrentPage(c);
                        setPerPage(s);
                      }}
                      current={meta?.current}
                      pageSize={meta?.pageSize}
                      total={meta?.total}
                    />
                  )}
                </TabPane>

                <TabPane
                  tab={t("O'zgartirilgan")}
                  key="2"
                  style={{ width: "100%" }}
                >
                  {applicants?.length > 0 ? (
                    <Table className="table table-responsive table-bordered table-striped table-hover text-center">
                      <Thead className="table-head">
                        <Tr>
                          <Th>№</Th>
                          <Th>{t("FIO")}</Th>
                          <Th>{t("Tug'ilgan sana")}</Th>
                          <Th>{t("Manzil")}</Th>
                          <Th>{t("Ta`lim yo`nalishi")}</Th>
                          <Th>{t("Ta'lim tili")}</Th>
                          <Th>{t("Ta'lim shakli")}</Th>
                          <Th>{t("Amallar")}</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {applicants?.map((v, i) => {
                          return (
                            <Tr>
                              <Td>{i + 1}</Td>
                              <Td>
                                {v?.name} {v?.surname} {v?.middle_name}
                              </Td>
                              <Td>{v?.birthdate}</Td>
                              <Td>{v?.address}</Td>
                              <Td>
                                {v?.faculty
                                  ? v?.faculty[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                {v?.education_language
                                  ? v?.education_language[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                {v?.education_type
                                  ? v?.education_type[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                <Button type="link">
                                  <NavLink to={"/applicant_viewer/" + v?.id}>
                                    <FaEye className="edit_button" />
                                  </NavLink>
                                </Button>
                                <Button
                                  type="link"
                                  onClick={() => downloadFile(v?.id)}
                                >
                                  <FaDownload />
                                </Button>
                                {auth?.role?.includes("super-admin") ? (
                                  <Popconfirm
                                    title={t(
                                      "Ma'lumotni o'chirishni tasdiqlaysizmi?"
                                    )}
                                    okText={t("Ha")}
                                    cancelText={t("Yo'q")}
                                    visible={visible == v?.id}
                                    onConfirm={() => delete_applicant(v?.id)}
                                    okButtonProps={{
                                      loading: confirmLoading,
                                    }}
                                    onCancel={handleCancel}
                                  >
                                    <Button
                                      onClick={() => showPopconfirm(v.id)}
                                      className="text-danger"
                                      type="link"
                                    >
                                      <FaTrashAlt />
                                    </Button>
                                  </Popconfirm>
                                ) : (
                                  ""
                                )}
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  ) : (
                    <Empty />
                  )}
                  {applicants?.length > 0 && (
                    <Pagination
                      showSizeChanger
                      onChange={(c, s) => {
                        setCurrentPage(c);
                        setPerPage(s);
                      }}
                      onShowSizeChange={(c, s) => {
                        setCurrentPage(c);
                        setPerPage(s);
                      }}
                      current={meta?.current}
                      pageSize={meta?.pageSize}
                      total={meta?.total}
                    />
                  )}
                </TabPane>

                <TabPane
                  tab={t("Qabul qilingan")}
                  key="3"
                  style={{ width: "100%" }}
                >
                  {applicants?.length > 0 ? (
                    <Table className="table table-responsive table-bordered table-striped table-hover text-center">
                      <Thead className="table-head">
                        <Tr>
                          <Th>№</Th>
                          <Th>{t("FIO")}</Th>
                          <Th>{t("Tug'ilgan sana")}</Th>
                          <Th>{t("Manzil")}</Th>
                          <Th>{t("Ta`lim yo`nalishi")}</Th>
                          <Th>{t("Ta'lim tili")}</Th>
                          <Th>{t("Ta'lim shakli")}</Th>
                          <Th>{t("Amallar")}</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {applicants?.map((v, i) => {
                          return (
                            <Tr>
                              <Td>{i + 1}</Td>
                              <Td>
                                {v?.name} {v?.surname} {v?.middle_name}
                              </Td>
                              <Td>{v?.birthdate}</Td>
                              <Td>{v?.address}</Td>
                              <Td>
                                {v?.faculty
                                  ? v?.faculty[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                {v?.education_language
                                  ? v?.education_language[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                {v?.education_type
                                  ? v?.education_type[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                <Button type="link">
                                  <NavLink to={"/applicant_viewer/" + v?.id}>
                                    <FaEye className="edit_button" />
                                  </NavLink>
                                </Button>
                                <Button
                                  type="link"
                                  onClick={() => downloadFile(v?.id)}
                                >
                                  <FaDownload />
                                </Button>
                                {auth?.role?.includes("super-admin") ? (
                                  <Popconfirm
                                    title={t(
                                      "Ma'lumotni o'chirishni tasdiqlaysizmi?"
                                    )}
                                    okText={t("Ha")}
                                    cancelText={t("Yo'q")}
                                    visible={visible == v?.id}
                                    onConfirm={() => delete_applicant(v?.id)}
                                    okButtonProps={{
                                      loading: confirmLoading,
                                    }}
                                    onCancel={handleCancel}
                                  >
                                    <Button
                                      onClick={() => showPopconfirm(v.id)}
                                      className="text-danger"
                                      type="link"
                                    >
                                      <FaTrashAlt />
                                    </Button>
                                  </Popconfirm>
                                ) : (
                                  ""
                                )}
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  ) : (
                    <Empty />
                  )}
                  {applicants?.length > 0 && (
                    <Pagination
                      showSizeChanger
                      onChange={(c, s) => {
                        setCurrentPage(c);
                        setPerPage(s);
                      }}
                      onShowSizeChange={(c, s) => {
                        setCurrentPage(c);
                        setPerPage(s);
                      }}
                      current={meta?.current}
                      pageSize={meta?.pageSize}
                      total={meta?.total}
                    />
                  )}
                </TabPane>
              </Tabs>
            </TabPane>
          ) : (
            ""
          )}

          {["super-admin", "receiver_malaka_oshirish"].includes(
            auth?.role[0]
          ) ? (
            <TabPane tab={t("Qayta tayyorlov")} key="2">
              <Tabs
                activeKey={statusTabActiveKey}
                onChange={(e) => setStatusTabActiveKey(`${e}`)}
              >
                <TabPane
                  tab={t("Topshirilgan")}
                  key="0"
                  style={{ width: "100%" }}
                >
                  {applicants?.length > 0 ? (
                    <Table className="table table-responsive table-bordered table-striped table-hover text-center">
                      <Thead className="table-head">
                        <Tr>
                          <Th>№</Th>
                          <Th>{t("FIO")}</Th>
                          <Th>{t("Tug'ilgan sana")}</Th>
                          <Th>{t("Manzil")}</Th>
                          <Th>{t("Ta`lim yo`nalishi")}</Th>
                          <Th>{t("Ta'lim tili")}</Th>
                          <Th>{t("Ta'lim shakli")}</Th>
                          <Th>{t("Amallar")}</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {applicants?.map((v, i) => {
                          return (
                            <Tr>
                              <Td>{i + 1}</Td>
                              <Td>
                                {v?.name} {v?.surname} {v?.middle_name}
                              </Td>
                              <Td>{v?.birthdate}</Td>
                              <Td>{v?.address}</Td>
                              <Td>
                                {v?.faculty
                                  ? v?.faculty[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                {v?.education_language
                                  ? v?.education_language[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                {v?.education_type
                                  ? v?.education_type[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                <Button type="link">
                                  <NavLink to={"/applicant_viewer/" + v?.id}>
                                    <FaEye className="edit_button" />
                                  </NavLink>
                                </Button>
                                <Button
                                  type="link"
                                  onClick={() => downloadFile(v?.id)}
                                >
                                  <FaDownload />
                                </Button>
                                {auth?.role?.includes("super-admin") ? (
                                  <Popconfirm
                                    title={t(
                                      "Ma'lumotni o'chirishni tasdiqlaysizmi?"
                                    )}
                                    okText={t("Ha")}
                                    cancelText={t("Yo'q")}
                                    visible={visible == v?.id}
                                    onConfirm={() => delete_applicant(v?.id)}
                                    okButtonProps={{
                                      loading: confirmLoading,
                                    }}
                                    onCancel={handleCancel}
                                  >
                                    <Button
                                      onClick={() => showPopconfirm(v.id)}
                                      className="text-danger"
                                      type="link"
                                    >
                                      <FaTrashAlt />
                                    </Button>
                                  </Popconfirm>
                                ) : (
                                  ""
                                )}
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  ) : (
                    <Empty />
                  )}
                  {applicants?.length > 0 && (
                    <Pagination
                      showSizeChanger
                      onChange={(c, s) => {
                        setCurrentPage(c);
                        setPerPage(s);
                      }}
                      onShowSizeChange={(c, s) => {
                        setCurrentPage(c);
                        setPerPage(s);
                      }}
                      current={meta?.current}
                      pageSize={meta?.pageSize}
                      total={meta?.total}
                    />
                  )}
                </TabPane>

                <TabPane
                  tab={t("Qaytarilgan")}
                  key="1"
                  style={{ width: "100%" }}
                >
                  {applicants?.length > 0 ? (
                    <Table className="table table-responsive table-bordered table-striped table-hover text-center">
                      <Thead className="table-head">
                        <Tr>
                          <Th>№</Th>
                          <Th>{t("FIO")}</Th>
                          <Th>{t("Tug'ilgan sana")}</Th>
                          <Th>{t("Manzil")}</Th>
                          <Th>{t("Ta`lim yo`nalishi")}</Th>
                          <Th>{t("Ta'lim tili")}</Th>
                          <Th>{t("Ta'lim shakli")}</Th>
                          <Th>{t("Amallar")}</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {applicants?.map((v, i) => {
                          return (
                            <Tr>
                              <Td>{i + 1}</Td>
                              <Td>
                                {v?.name} {v?.surname} {v?.middle_name}
                              </Td>
                              <Td>{v?.birthdate}</Td>
                              <Td>{v?.address}</Td>
                              <Td>
                                {v?.faculty
                                  ? v?.faculty[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                {v?.education_language
                                  ? v?.education_language[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                {v?.education_type
                                  ? v?.education_type[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                <Button type="link">
                                  <NavLink to={"/applicant_viewer/" + v?.id}>
                                    <FaEye className="edit_button" />
                                  </NavLink>
                                </Button>
                                <Button
                                  type="link"
                                  onClick={() => downloadFile(v?.id)}
                                >
                                  <FaDownload />
                                </Button>
                                {auth?.role?.includes("super-admin") ? (
                                  <Popconfirm
                                    title={t(
                                      "Ma'lumotni o'chirishni tasdiqlaysizmi?"
                                    )}
                                    okText={t("Ha")}
                                    cancelText={t("Yo'q")}
                                    visible={visible == v?.id}
                                    onConfirm={() => delete_applicant(v?.id)}
                                    okButtonProps={{
                                      loading: confirmLoading,
                                    }}
                                    onCancel={handleCancel}
                                  >
                                    <Button
                                      onClick={() => showPopconfirm(v.id)}
                                      className="text-danger"
                                      type="link"
                                    >
                                      <FaTrashAlt />
                                    </Button>
                                  </Popconfirm>
                                ) : (
                                  ""
                                )}
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  ) : (
                    <Empty />
                  )}
                  {applicants?.length > 0 && (
                    <Pagination
                      showSizeChanger
                      onChange={(c, s) => {
                        setCurrentPage(c);
                        setPerPage(s);
                      }}
                      onShowSizeChange={(c, s) => {
                        setCurrentPage(c);
                        setPerPage(s);
                      }}
                      current={meta?.current}
                      pageSize={meta?.pageSize}
                      total={meta?.total}
                    />
                  )}
                </TabPane>

                <TabPane
                  tab={t("O'zgartirilgan")}
                  key="2"
                  style={{ width: "100%" }}
                >
                  {applicants?.length > 0 ? (
                    <Table className="table table-responsive table-bordered table-striped table-hover text-center">
                      <Thead className="table-head">
                        <Tr>
                          <Th>№</Th>
                          <Th>{t("FIO")}</Th>
                          <Th>{t("Tug'ilgan sana")}</Th>
                          <Th>{t("Manzil")}</Th>
                          <Th>{t("Ta`lim yo`nalishi")}</Th>
                          <Th>{t("Ta'lim tili")}</Th>
                          <Th>{t("Ta'lim shakli")}</Th>
                          <Th>{t("Amallar")}</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {applicants?.map((v, i) => {
                          return (
                            <Tr>
                              <Td>{i + 1}</Td>
                              <Td>
                                {v?.name} {v?.surname} {v?.middle_name}
                              </Td>
                              <Td>{v?.birthdate}</Td>
                              <Td>{v?.address}</Td>
                              <Td>
                                {v?.faculty
                                  ? v?.faculty[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                {v?.education_language
                                  ? v?.education_language[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                {v?.education_type
                                  ? v?.education_type[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                <Button type="link">
                                  <NavLink to={"/applicant_viewer/" + v?.id}>
                                    <FaEye className="edit_button" />
                                  </NavLink>
                                </Button>
                                <Button
                                  type="link"
                                  onClick={() => downloadFile(v?.id)}
                                >
                                  <FaDownload />
                                </Button>
                                {auth?.role?.includes("super-admin") ? (
                                  <Popconfirm
                                    title={t(
                                      "Ma'lumotni o'chirishni tasdiqlaysizmi?"
                                    )}
                                    okText={t("Ha")}
                                    cancelText={t("Yo'q")}
                                    visible={visible == v?.id}
                                    onConfirm={() => delete_applicant(v?.id)}
                                    okButtonProps={{
                                      loading: confirmLoading,
                                    }}
                                    onCancel={handleCancel}
                                  >
                                    <Button
                                      onClick={() => showPopconfirm(v.id)}
                                      className="text-danger"
                                      type="link"
                                    >
                                      <FaTrashAlt />
                                    </Button>
                                  </Popconfirm>
                                ) : (
                                  ""
                                )}
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  ) : (
                    <Empty />
                  )}
                  {applicants?.length > 0 && (
                    <Pagination
                      showSizeChanger
                      onChange={(c, s) => {
                        setCurrentPage(c);
                        setPerPage(s);
                      }}
                      onShowSizeChange={(c, s) => {
                        setCurrentPage(c);
                        setPerPage(s);
                      }}
                      current={meta?.current}
                      pageSize={meta?.pageSize}
                      total={meta?.total}
                    />
                  )}
                </TabPane>

                <TabPane
                  tab={t("Qabul qilingan")}
                  key="3"
                  style={{ width: "100%" }}
                >
                  {applicants?.length > 0 ? (
                    <Table className="table table-responsive table-bordered table-striped table-hover text-center">
                      <Thead className="table-head">
                        <Tr>
                          <Th>№</Th>
                          <Th>{t("FIO")}</Th>
                          <Th>{t("Tug'ilgan sana")}</Th>
                          <Th>{t("Manzil")}</Th>
                          <Th>{t("Ta`lim yo`nalishi")}</Th>
                          <Th>{t("Ta'lim tili")}</Th>
                          <Th>{t("Ta'lim shakli")}</Th>
                          <Th>{t("Amallar")}</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {applicants?.map((v, i) => {
                          return (
                            <Tr>
                              <Td>{i + 1}</Td>
                              <Td>
                                {v?.name} {v?.surname} {v?.middle_name}
                              </Td>
                              <Td>{v?.birthdate}</Td>
                              <Td>{v?.address}</Td>
                              <Td>
                                {v?.faculty
                                  ? v?.faculty[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                {v?.education_language
                                  ? v?.education_language[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                {v?.education_type
                                  ? v?.education_type[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                <Button type="link">
                                  <NavLink to={"/applicant_viewer/" + v?.id}>
                                    <FaEye className="edit_button" />
                                  </NavLink>
                                </Button>
                                <Button
                                  type="link"
                                  onClick={() => downloadFile(v?.id)}
                                >
                                  <FaDownload />
                                </Button>
                                {auth?.role?.includes("super-admin") ? (
                                  <Popconfirm
                                    title={t(
                                      "Ma'lumotni o'chirishni tasdiqlaysizmi?"
                                    )}
                                    okText={t("Ha")}
                                    cancelText={t("Yo'q")}
                                    visible={visible == v?.id}
                                    onConfirm={() => delete_applicant(v?.id)}
                                    okButtonProps={{
                                      loading: confirmLoading,
                                    }}
                                    onCancel={handleCancel}
                                  >
                                    <Button
                                      onClick={() => showPopconfirm(v.id)}
                                      className="text-danger"
                                      type="link"
                                    >
                                      <FaTrashAlt />
                                    </Button>
                                  </Popconfirm>
                                ) : (
                                  ""
                                )}
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  ) : (
                    <Empty />
                  )}
                  {applicants?.length > 0 && (
                    <Pagination
                      showSizeChanger
                      onChange={(c, s) => {
                        setCurrentPage(c);
                        setPerPage(s);
                      }}
                      onShowSizeChange={(c, s) => {
                        setCurrentPage(c);
                        setPerPage(s);
                      }}
                      current={meta?.current}
                      pageSize={meta?.pageSize}
                      total={meta?.total}
                    />
                  )}
                </TabPane>
              </Tabs>
            </TabPane>
          ) : (
            ""
          )}

          {["super-admin", "receiver"].includes(auth?.role[0]) ? (
            <TabPane tab={t("Bakalavr")} key="3">
              <Tabs
                activeKey={statusTabActiveKey}
                onChange={(e) => setStatusTabActiveKey(`${e}`)}
              >
                <TabPane
                  tab={t("Topshirilgan")}
                  key="0"
                  style={{ width: "100%" }}
                >
                  {applicants?.length > 0 ? (
                    <Table className="table table-responsive table-bordered table-striped table-hover text-center">
                      <Thead className="table-head">
                        <Tr>
                          <Th>№</Th>
                          <Th>{t("FIO")}</Th>
                          <Th>{t("Tug'ilgan sana")}</Th>
                          <Th>{t("Manzil")}</Th>
                          <Th>{t("Ta`lim yo`nalishi")}</Th>
                          <Th>{t("Ta'lim tili")}</Th>
                          <Th>{t("Ta'lim shakli")}</Th>
                          <Th>{t("Amallar")}</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {applicants?.map((v, i) => {
                          return (
                            <Tr>
                              <Td>{i + 1}</Td>
                              <Td>
                                {v?.name} {v?.surname} {v?.middle_name}
                              </Td>
                              <Td>{v?.birthdate}</Td>
                              <Td>{v?.address}</Td>
                              <Td>
                                {v?.faculty
                                  ? v?.faculty[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                {v?.education_language
                                  ? v?.education_language[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                {v?.education_type
                                  ? v?.education_type[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                <Button type="link">
                                  <NavLink to={"/applicant_viewer/" + v?.id}>
                                    <FaEye className="edit_button" />
                                  </NavLink>
                                </Button>
                                <Button
                                  type="link"
                                  onClick={() => downloadFile(v?.id)}
                                >
                                  <FaDownload />
                                </Button>
                                {auth?.role?.includes("super-admin") ? (
                                  <Popconfirm
                                    title={t(
                                      "Ma'lumotni o'chirishni tasdiqlaysizmi?"
                                    )}
                                    okText={t("Ha")}
                                    cancelText={t("Yo'q")}
                                    visible={visible == v?.id}
                                    onConfirm={() => delete_applicant(v?.id)}
                                    okButtonProps={{
                                      loading: confirmLoading,
                                    }}
                                    onCancel={handleCancel}
                                  >
                                    <Button
                                      onClick={() => showPopconfirm(v.id)}
                                      className="text-danger"
                                      type="link"
                                    >
                                      <FaTrashAlt />
                                    </Button>
                                  </Popconfirm>
                                ) : (
                                  ""
                                )}
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  ) : (
                    <Empty />
                  )}
                  {applicants?.length > 0 && (
                    <Pagination
                      showSizeChanger
                      onChange={(c, s) => {
                        setCurrentPage(c);
                        setPerPage(s);
                      }}
                      onShowSizeChange={(c, s) => {
                        setCurrentPage(c);
                        setPerPage(s);
                      }}
                      current={meta?.current}
                      pageSize={meta?.pageSize}
                      total={meta?.total}
                    />
                  )}
                </TabPane>

                <TabPane
                  tab={t("Qaytarilgan")}
                  key="1"
                  style={{ width: "100%" }}
                >
                  {applicants?.length > 0 ? (
                    <Table className="table table-responsive table-bordered table-striped table-hover text-center">
                      <Thead className="table-head">
                        <Tr>
                          <Th>№</Th>
                          <Th>{t("FIO")}</Th>
                          <Th>{t("Tug'ilgan sana")}</Th>
                          <Th>{t("Manzil")}</Th>
                          <Th>{t("Ta`lim yo`nalishi")}</Th>
                          <Th>{t("Ta'lim tili")}</Th>
                          <Th>{t("Ta'lim shakli")}</Th>
                          <Th>{t("Amallar")}</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {applicants?.map((v, i) => {
                          return (
                            <Tr>
                              <Td>{i + 1}</Td>
                              <Td>
                                {v?.name} {v?.surname} {v?.middle_name}
                              </Td>
                              <Td>{v?.birthdate}</Td>
                              <Td>{v?.address}</Td>
                              <Td>
                                {v?.faculty
                                  ? v?.faculty[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                {v?.education_language
                                  ? v?.education_language[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                {v?.education_type
                                  ? v?.education_type[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                <Button type="link">
                                  <NavLink to={"/applicant_viewer/" + v?.id}>
                                    <FaEye className="edit_button" />
                                  </NavLink>
                                </Button>
                                <Button
                                  type="link"
                                  onClick={() => downloadFile(v?.id)}
                                >
                                  <FaDownload />
                                </Button>
                                {auth?.role?.includes("super-admin") ? (
                                  <Popconfirm
                                    title={t(
                                      "Ma'lumotni o'chirishni tasdiqlaysizmi?"
                                    )}
                                    okText={t("Ha")}
                                    cancelText={t("Yo'q")}
                                    visible={visible == v?.id}
                                    onConfirm={() => delete_applicant(v?.id)}
                                    okButtonProps={{
                                      loading: confirmLoading,
                                    }}
                                    onCancel={handleCancel}
                                  >
                                    <Button
                                      onClick={() => showPopconfirm(v.id)}
                                      className="text-danger"
                                      type="link"
                                    >
                                      <FaTrashAlt />
                                    </Button>
                                  </Popconfirm>
                                ) : (
                                  ""
                                )}
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  ) : (
                    <Empty />
                  )}
                  {applicants?.length > 0 && (
                    <Pagination
                      showSizeChanger
                      onChange={(c, s) => {
                        setCurrentPage(c);
                        setPerPage(s);
                      }}
                      onShowSizeChange={(c, s) => {
                        setCurrentPage(c);
                        setPerPage(s);
                      }}
                      current={meta?.current}
                      pageSize={meta?.pageSize}
                      total={meta?.total}
                    />
                  )}
                </TabPane>

                <TabPane
                  tab={t("O'zgartirilgan")}
                  key="2"
                  style={{ width: "100%" }}
                >
                  {applicants?.length > 0 ? (
                    <Table className="table table-responsive table-bordered table-striped table-hover text-center">
                      <Thead className="table-head">
                        <Tr>
                          <Th>№</Th>
                          <Th>{t("FIO")}</Th>
                          <Th>{t("Tug'ilgan sana")}</Th>
                          <Th>{t("Manzil")}</Th>
                          <Th>{t("Ta`lim yo`nalishi")}</Th>
                          <Th>{t("Ta'lim tili")}</Th>
                          <Th>{t("Ta'lim shakli")}</Th>
                          <Th>{t("Amallar")}</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {applicants?.map((v, i) => {
                          return (
                            <Tr>
                              <Td>{i + 1}</Td>
                              <Td>
                                {v?.name} {v?.surname} {v?.middle_name}
                              </Td>
                              <Td>{v?.birthdate}</Td>
                              <Td>{v?.address}</Td>
                              <Td>
                                {v?.faculty
                                  ? v?.faculty[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                {v?.education_language
                                  ? v?.education_language[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                {v?.education_type
                                  ? v?.education_type[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                <Button type="link">
                                  <NavLink to={"/applicant_viewer/" + v?.id}>
                                    <FaEye className="edit_button" />
                                  </NavLink>
                                </Button>
                                <Button
                                  type="link"
                                  onClick={() => downloadFile(v?.id)}
                                >
                                  <FaDownload />
                                </Button>
                                {auth?.role?.includes("super-admin") ? (
                                  <Popconfirm
                                    title={t(
                                      "Ma'lumotni o'chirishni tasdiqlaysizmi?"
                                    )}
                                    okText={t("Ha")}
                                    cancelText={t("Yo'q")}
                                    visible={visible == v?.id}
                                    onConfirm={() => delete_applicant(v?.id)}
                                    okButtonProps={{
                                      loading: confirmLoading,
                                    }}
                                    onCancel={handleCancel}
                                  >
                                    <Button
                                      onClick={() => showPopconfirm(v.id)}
                                      className="text-danger"
                                      type="link"
                                    >
                                      <FaTrashAlt />
                                    </Button>
                                  </Popconfirm>
                                ) : (
                                  ""
                                )}
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  ) : (
                    <Empty />
                  )}
                  {applicants?.length > 0 && (
                    <Pagination
                      showSizeChanger
                      onChange={(c, s) => {
                        setCurrentPage(c);
                        setPerPage(s);
                      }}
                      onShowSizeChange={(c, s) => {
                        setCurrentPage(c);
                        setPerPage(s);
                      }}
                      current={meta?.current}
                      pageSize={meta?.pageSize}
                      total={meta?.total}
                    />
                  )}
                </TabPane>

                <TabPane
                  tab={t("Qabul qilingan")}
                  key="3"
                  style={{ width: "100%" }}
                >
                  {applicants?.length > 0 ? (
                    <Table className="table table-responsive table-bordered table-striped table-hover text-center">
                      <Thead className="table-head">
                        <Tr>
                          <Th>№</Th>
                          <Th>{t("FIO")}</Th>
                          <Th>{t("Tug'ilgan sana")}</Th>
                          <Th>{t("Manzil")}</Th>
                          <Th>{t("Ta`lim yo`nalishi")}</Th>
                          <Th>{t("Ta'lim tili")}</Th>
                          <Th>{t("Ta'lim shakli")}</Th>
                          <Th>{t("Amallar")}</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {applicants?.map((v, i) => {
                          return (
                            <Tr>
                              <Td>{i + 1}</Td>
                              <Td>
                                {v?.name} {v?.surname} {v?.middle_name}
                              </Td>
                              <Td>{v?.birthdate}</Td>
                              <Td>{v?.address}</Td>
                              <Td>
                                {v?.faculty
                                  ? v?.faculty[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                {v?.education_language
                                  ? v?.education_language[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                {v?.education_type
                                  ? v?.education_type[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                <Button type="link">
                                  <NavLink to={"/applicant_viewer/" + v?.id}>
                                    <FaEye className="edit_button" />
                                  </NavLink>
                                </Button>
                                <Button
                                  type="link"
                                  onClick={() => downloadFile(v?.id)}
                                >
                                  <FaDownload />
                                </Button>
                                {auth?.role?.includes("super-admin") ? (
                                  <Popconfirm
                                    title={t(
                                      "Ma'lumotni o'chirishni tasdiqlaysizmi?"
                                    )}
                                    okText={t("Ha")}
                                    cancelText={t("Yo'q")}
                                    visible={visible == v?.id}
                                    onConfirm={() => delete_applicant(v?.id)}
                                    okButtonProps={{
                                      loading: confirmLoading,
                                    }}
                                    onCancel={handleCancel}
                                  >
                                    <Button
                                      onClick={() => showPopconfirm(v.id)}
                                      className="text-danger"
                                      type="link"
                                    >
                                      <FaTrashAlt />
                                    </Button>
                                  </Popconfirm>
                                ) : (
                                  ""
                                )}
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  ) : (
                    <Empty />
                  )}
                  {applicants?.length > 0 && (
                    <Pagination
                      showSizeChanger
                      onChange={(c, s) => {
                        console.log(
                          "fdsdfsdfsfsdfsdfs---------------------------------->        ",
                          c,
                          s
                        );
                        setCurrentPage(c);
                        setPerPage(s);
                      }}
                      onShowSizeChange={(c, s) => {
                        console.log(
                          "fdsdfsdfsfsdfsdfs---------------------------------->        ",
                          c,
                          s
                        );
                        setCurrentPage(c);
                        setPerPage(s);
                      }}
                      current={meta?.current}
                      pageSize={meta?.pageSize}
                      total={meta?.total}
                    />
                  )}
                </TabPane>
              </Tabs>
            </TabPane>
          ) : (
            ""
          )}

          {["super-admin", "receiver"].includes(auth?.role[0]) ? (
            <TabPane tab={t("Magistratura")} key="4">
              <Tabs
                activeKey={statusTabActiveKey}
                onChange={(e) => setStatusTabActiveKey(`${e}`)}
              >
                <TabPane
                  tab={t("Topshirilgan")}
                  key="0"
                  style={{ width: "100%" }}
                >
                  {applicants?.length > 0 ? (
                    <Table className="table table-responsive table-bordered table-striped table-hover text-center">
                      <Thead className="table-head">
                        <Tr>
                          <Th>№</Th>
                          <Th>{t("FIO")}</Th>
                          <Th>{t("Tug'ilgan sana")}</Th>
                          <Th>{t("Manzil")}</Th>
                          <Th>{t("Magistratura mutaxasisliklari")}</Th>
                          <Th>{t("Ta'lim tili")}</Th>
                          <Th>{t("Ta'lim shakli")}</Th>
                          <Th>{t("Amallar")}</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {applicants?.map((v, i) => {
                          return (
                            <Tr>
                              <Td>{i + 1}</Td>
                              <Td>
                                {v?.name} {v?.surname} {v?.middle_name}
                              </Td>
                              <Td>{v?.birthdate}</Td>
                              <Td>{v?.address}</Td>
                              <Td>
                                {v?.faculty
                                  ? v?.faculty[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                {v?.education_language
                                  ? v?.education_language[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                {v?.education_type
                                  ? v?.education_type[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                <Button type="link">
                                  <NavLink to={"/applicant_viewer/" + v?.id}>
                                    <FaEye className="edit_button" />
                                  </NavLink>
                                </Button>
                                <Button
                                  type="link"
                                  onClick={() => downloadFile(v?.id)}
                                >
                                  <FaDownload />
                                </Button>
                                {auth?.role?.includes("super-admin") ? (
                                  <Popconfirm
                                    title={t(
                                      "Ma'lumotni o'chirishni tasdiqlaysizmi?"
                                    )}
                                    okText={t("Ha")}
                                    cancelText={t("Yo'q")}
                                    visible={visible == v?.id}
                                    onConfirm={() => delete_applicant(v?.id)}
                                    okButtonProps={{
                                      loading: confirmLoading,
                                    }}
                                    onCancel={handleCancel}
                                  >
                                    <Button
                                      onClick={() => showPopconfirm(v.id)}
                                      className="text-danger"
                                      type="link"
                                    >
                                      <FaTrashAlt />
                                    </Button>
                                  </Popconfirm>
                                ) : (
                                  ""
                                )}
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  ) : (
                    <Empty />
                  )}
                  {applicants?.length > 0 && (
                    <Pagination
                      showSizeChanger
                      onChange={(c, s) => {
                        setCurrentPage(c);
                        setPerPage(s);
                      }}
                      onShowSizeChange={(c, s) => {
                        setCurrentPage(c);
                        setPerPage(s);
                      }}
                      current={meta?.current}
                      pageSize={meta?.pageSize}
                      total={meta?.total}
                    />
                  )}
                </TabPane>

                <TabPane
                  tab={t("Qaytarilgan")}
                  key="1"
                  style={{ width: "100%" }}
                >
                  {applicants?.length > 0 ? (
                    <Table className="table table-responsive table-bordered table-striped table-hover text-center">
                      <Thead className="table-head">
                        <Tr>
                          <Th>№</Th>
                          <Th>{t("FIO")}</Th>
                          <Th>{t("Tug'ilgan sana")}</Th>
                          <Th>{t("Manzil")}</Th>
                          <Th>{t("Magistratura mutaxasisliklari")}</Th>
                          <Th>{t("Ta'lim tili")}</Th>
                          <Th>{t("Ta'lim shakli")}</Th>
                          <Th>{t("Amallar")}</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {applicants?.map((v, i) => {
                          return (
                            <Tr>
                              <Td>{i + 1}</Td>
                              <Td>
                                {v?.name} {v?.surname} {v?.middle_name}
                              </Td>
                              <Td>{v?.birthdate}</Td>
                              <Td>{v?.address}</Td>
                              <Td>
                                {v?.faculty
                                  ? v?.faculty[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                {v?.education_language
                                  ? v?.education_language[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                {v?.education_type
                                  ? v?.education_type[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                <Button type="link">
                                  <NavLink to={"/applicant_viewer/" + v?.id}>
                                    <FaEye className="edit_button" />
                                  </NavLink>
                                </Button>
                                <Button
                                  type="link"
                                  onClick={() => downloadFile(v?.id)}
                                >
                                  <FaDownload />
                                </Button>
                                {auth?.role?.includes("super-admin") ? (
                                  <Popconfirm
                                    title={t(
                                      "Ma'lumotni o'chirishni tasdiqlaysizmi?"
                                    )}
                                    okText={t("Ha")}
                                    cancelText={t("Yo'q")}
                                    visible={visible == v?.id}
                                    onConfirm={() => delete_applicant(v?.id)}
                                    okButtonProps={{
                                      loading: confirmLoading,
                                    }}
                                    onCancel={handleCancel}
                                  >
                                    <Button
                                      onClick={() => showPopconfirm(v.id)}
                                      className="text-danger"
                                      type="link"
                                    >
                                      <FaTrashAlt />
                                    </Button>
                                  </Popconfirm>
                                ) : (
                                  ""
                                )}
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  ) : (
                    <Empty />
                  )}
                  {applicants?.length > 0 && (
                    <Pagination
                      showSizeChanger
                      onChange={(c, s) => {
                        setCurrentPage(c);
                        setPerPage(s);
                      }}
                      onShowSizeChange={(c, s) => {
                        setCurrentPage(c);
                        setPerPage(s);
                      }}
                      current={meta?.current}
                      pageSize={meta?.pageSize}
                      total={meta?.total}
                    />
                  )}
                </TabPane>

                <TabPane
                  tab={t("O'zgartirilgan")}
                  key="2"
                  style={{ width: "100%" }}
                >
                  {applicants?.length > 0 ? (
                    <Table className="table table-responsive table-bordered table-striped table-hover text-center">
                      <Thead className="table-head">
                        <Tr>
                          <Th>№</Th>
                          <Th>{t("FIO")}</Th>
                          <Th>{t("Tug'ilgan sana")}</Th>
                          <Th>{t("Manzil")}</Th>
                          <Th>{t("Magistratura mutaxasisliklari")}</Th>
                          <Th>{t("Ta'lim tili")}</Th>
                          <Th>{t("Ta'lim shakli")}</Th>
                          <Th>{t("Amallar")}</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {applicants?.map((v, i) => {
                          return (
                            <Tr>
                              <Td>{i + 1}</Td>
                              <Td>
                                {v?.name} {v?.surname} {v?.middle_name}
                              </Td>
                              <Td>{v?.birthdate}</Td>
                              <Td>{v?.address}</Td>
                              <Td>
                                {v?.faculty
                                  ? v?.faculty[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                {v?.education_language
                                  ? v?.education_language[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                {v?.education_type
                                  ? v?.education_type[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                <Button type="link">
                                  <NavLink to={"/applicant_viewer/" + v?.id}>
                                    <FaEye className="edit_button" />
                                  </NavLink>
                                </Button>
                                <Button
                                  type="link"
                                  onClick={() => downloadFile(v?.id)}
                                >
                                  <FaDownload />
                                </Button>
                                {auth?.role?.includes("super-admin") ? (
                                  <Popconfirm
                                    title={t(
                                      "Ma'lumotni o'chirishni tasdiqlaysizmi?"
                                    )}
                                    okText={t("Ha")}
                                    cancelText={t("Yo'q")}
                                    visible={visible == v?.id}
                                    onConfirm={() => delete_applicant(v?.id)}
                                    okButtonProps={{
                                      loading: confirmLoading,
                                    }}
                                    onCancel={handleCancel}
                                  >
                                    <Button
                                      onClick={() => showPopconfirm(v.id)}
                                      className="text-danger"
                                      type="link"
                                    >
                                      <FaTrashAlt />
                                    </Button>
                                  </Popconfirm>
                                ) : (
                                  ""
                                )}
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  ) : (
                    <Empty />
                  )}
                  {applicants?.length > 0 && (
                    <Pagination
                      showSizeChanger
                      onChange={(c, s) => {
                        setCurrentPage(c);
                        setPerPage(s);
                      }}
                      onShowSizeChange={(c, s) => {
                        setCurrentPage(c);
                        setPerPage(s);
                      }}
                      current={meta?.current}
                      pageSize={meta?.pageSize}
                      total={meta?.total}
                    />
                  )}
                </TabPane>

                <TabPane
                  tab={t("Qabul qilingan")}
                  key="3"
                  style={{ width: "100%" }}
                >
                  {applicants?.length > 0 ? (
                    <Table className="table table-responsive table-bordered table-striped table-hover text-center">
                      <Thead className="table-head">
                        <Tr>
                          <Th>№</Th>
                          <Th>{t("FIO")}</Th>
                          <Th>{t("Tug'ilgan sana")}</Th>
                          <Th>{t("Manzil")}</Th>
                          <Th>{t("Magistratura mutaxasisliklari")}</Th>
                          <Th>{t("Ta'lim tili")}</Th>
                          <Th>{t("Ta'lim shakli")}</Th>
                          <Th>{t("Amallar")}</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {applicants?.map((v, i) => {
                          return (
                            <Tr>
                              <Td>{i + 1}</Td>
                              <Td>
                                {v?.name} {v?.surname} {v?.middle_name}
                              </Td>
                              <Td>{v?.birthdate}</Td>
                              <Td>{v?.address}</Td>
                              <Td>
                                {v?.faculty
                                  ? v?.faculty[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                {v?.education_language
                                  ? v?.education_language[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                {v?.education_type
                                  ? v?.education_type[
                                      i18next.language === "uz"
                                        ? "name_uz"
                                        : "name_ru"
                                    ]
                                  : ""}
                              </Td>
                              <Td>
                                <Button type="link">
                                  <NavLink to={"/applicant_viewer/" + v?.id}>
                                    <FaEye className="edit_button" />
                                  </NavLink>
                                </Button>
                                <Button
                                  type="link"
                                  onClick={() => downloadFile(v?.id)}
                                >
                                  <FaDownload />
                                </Button>
                                {auth?.role?.includes("super-admin") ? (
                                  <Popconfirm
                                    title={t(
                                      "Ma'lumotni o'chirishni tasdiqlaysizmi?"
                                    )}
                                    okText={t("Ha")}
                                    cancelText={t("Yo'q")}
                                    visible={visible == v?.id}
                                    onConfirm={() => delete_applicant(v?.id)}
                                    okButtonProps={{
                                      loading: confirmLoading,
                                    }}
                                    onCancel={handleCancel}
                                  >
                                    <Button
                                      onClick={() => showPopconfirm(v.id)}
                                      className="text-danger"
                                      type="link"
                                    >
                                      <FaTrashAlt />
                                    </Button>
                                  </Popconfirm>
                                ) : (
                                  ""
                                )}
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  ) : (
                    <Empty />
                  )}
                  {applicants?.length > 0 && (
                    <Pagination
                      showSizeChanger
                      onChange={(c, s) => {
                        setCurrentPage(c);
                        setPerPage(s);
                      }}
                      onShowSizeChange={(c, s) => {
                        setCurrentPage(c);
                        setPerPage(s);
                      }}
                      current={meta?.current}
                      pageSize={meta?.pageSize}
                      total={meta?.total}
                    />
                  )}
                </TabPane>
              </Tabs>
            </TabPane>
          ) : (
            ""
          )}
        </Tabs>
      </Row>
    </Fragment>
  );
};

export default Checker;
