import React from "react";
import "./applicant.scss";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  message,
  Select,
  DatePicker,
  Upload,
  Spin,
  Divider,
  notification,
} from "antd";
import { useEffect } from "react";
import { useState } from "react";
import instance from "../../api/axios";
import ReactInputMask from "react-input-mask";
import { BsUpload } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import moment from "moment";
import { FILE_URL } from "../../api/constants";
import { useHistory } from "react-router-dom";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import jshrImage from "../../assets/images/Jshr.jpg";

const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}

const ApplyDocs = () => {
  // Hooks
  const history = useHistory();
  const [form] = Form.useForm();
  const [faculties, setFaculties] = useState([]);
  const [facultyItem, setFacultyItem] = useState([]);
  const [eduLangs, setEduLangs] = useState([]);
  const [eduTypes, setEduTypes] = useState([]);
  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const formatDate = "YYYY/MM/DD";
  const [loader, setLoader] = useState(false);
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState();
  const [directions, setDirections] = useState([]);
  const [facultyDirections, setFacultyDirections] = useState([]);
  const { t } = useTranslation();
  const [disableName, setDisableName] = useState([
    "name",
    "surname",
    "middle_name",
    "birth_date",
    "gender",
    "region",
    "district",
    "address",
    "telephone",
    "telephone2",
    "photo",
    "passport_serie",
    "passport_numbers",
    "passport_jshr",
    "passport_copy",
    "diploma_serie",
    "diploma_number",
    "diploma_copy",
    "faculty_id",
    "education_type_id",
    "education_language_id",
    "admission_type_id",
    "diploma_given",
    "direction_id",
  ]);
  const [fileUrls, setFileUrls] = useState({
    img: [],
    passport: [],
    diplom: [],
    sertificate: [],
  });

  // Form data functions
  const onFinish = async (values) => {
    console.log("Success:", values);
    form.validateFields().then(async (data) => {
      console.log(data);
      try {
        setLoader(true);
        const data = new FormData();
        Object.entries(values)?.forEach(([key, value]) => {
          if (value) {
            if (
              [
                "passport_copy",
                "photo",
                "diploma_copy",
                "qayta_tayyorlov_sertifikat",
              ].includes(key)
            ) {
              if (value?.file?.originFileObj)
                data.append(key, value?.file?.originFileObj);
            } else if (key === "birth_date") {
              data.append("birthdate", moment(value).format(formatDate));
            } else if (key === "diploma_given") {
              data.append(key, moment(value).format(formatDate));
            } else {
              data.append(key, value);
            }
          }
        });

        // post APi
        const response = await instance({
          url: "/application/store",
          method: "post",
          data: data,
          headers: {
            Accept: "application/json",
          },
        });
        history.push("/");
        setLoader(false);
      } catch (error) {
        setLoader(false);
        form.setFields([
          {
            name: ["passport_jshr"],
            errors: [...error?.response.data?.errors?.passport_jshr],
          },
        ]);
        notification["error"]({
          message: "Hujjatni yuborishda xatolik!",
          placement: "topRight",
          duration: 2,
        });
      }
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: "/faculties-directions",
        });
        setDirections(resp?.data?.data);
      } catch (e) {
        message.error(e?.response?.data?.message);
      }
    })();
  }, []);
  // get application infos
  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: "/user/application?expand=region,education_language,education_type",
        });
        let data = resp?.data?.data?.application;
        if (data?.region_id) {
          handleRegions(data?.region_id, true);
        }
        _getFacultyItem(data?.faculty_id);
        form.setFieldsValue({
          ...data,
          gender: data?.gender,
          region: data?.region_id,
          district: data?.district_id,
          faculty: data?.faculty_id,
          education_language_id: data?.education_language_id,
          education_type: data?.education_type_id,
          birth_date: moment(data?.birthdate),
          diploma_given: moment(data?.diploma_given),
          passport_series: data?.passport_serie,
          passport_number: data?.passport_numbers,
          call_number: data?.telephone,
          call_number_2: data?.telephone2,
          diplom_number: data?.diploma_number,
        });
        setSelectedType(data?.admission_type_id);
        setFileUrls({
          img: [
            {
              uid: "1",
              name:
                "image." +
                data?.photo?.substring(
                  data?.photo?.lastIndexOf(".") + 1,
                  data?.photo?.length
                ),
              ststus: "success",
              url: FILE_URL + data?.folder + "/" + data?.photo,
            },
          ],
          passport: [
            {
              uid: "1",
              name:
                "passport." +
                data?.passport_copy?.substring(
                  data?.passport_copy?.lastIndexOf(".") + 1,
                  data?.data?.passport_copy?.length
                ),
              ststus: "success",
              url: FILE_URL + data?.folder + "/" + data?.passport_copy,
            },
          ],
          diplom: [
            {
              uid: "1",
              name:
                "diplom." +
                data?.diploma_copy?.substring(
                  data?.diploma_copy?.lastIndexOf(".") + 1,
                  data?.diploma_copy?.length
                ),
              ststus: "success",
              url: FILE_URL + data?.folder + "/" + data?.diploma_copy,
            },
          ],
          sertificate: [
            {
              uid: "1",
              name:
                "sertificate." +
                data?.qayta_tayyorlov_sertifikat?.substring(
                  data?.qayta_tayyorlov_sertifikat?.lastIndexOf(".") + 1,
                  data?.qayta_tayyorlov_sertifikat?.length
                ),
              ststus: "success",
              url:
                FILE_URL +
                data?.folder +
                "/" +
                data?.qayta_tayyorlov_sertifikat,
            },
          ],
        });
        handleDirectionsByFacultyId(data?.faculty_id);
        let errors = [];
        let disNames = [];
        Object.entries(resp?.data?.data?.feedbacks).forEach(([key, value]) => {
          errors = [
            ...errors,
            {
              name: [key],
              errors: [value?.feedback],
              validating: false,
            },
          ];
          disNames = [...disNames, value?.column_name];
        });
        console.log(errors, disNames);
        form.setFields(errors);
        setDisableName(disNames);
      } catch (e) {
        // message.error("Error get your application");
      }
    })();
  }, []);

  // upload files
  const dummyRequest = ({ file, onSuccess }) => {
    console.log(file);
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  // get faculties with items
  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: "/faculties",
        });
        if (resp?.status === 200) {
          setFaculties(resp?.data?.faculties);
        }
      } catch (e) {
        message.error("Fakultetni ochishda xatolik!!");
      }
    })();
  }, []);

  const _getFacultyItem = async (id) => {
    if (id) {
      try {
        const resp = await instance({
          method: "get",
          url: `/faculties/${id}?expand=education_languages,education_types`,
        });
        if (resp?.status === 200) {
          setFacultyItem(resp?.data?.faculty);
          console.log(resp?.data);
        }
      } catch (e) {
        message.error("Ta'lim turi ma'lumotlarini ochishda xatolik!");
      }
    }
  };

  // get address
  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: "/regions",
        });
        if (resp?.status === 200) {
          setRegions(resp?.data?.data);
        }
      } catch (e) {
        message.error("Viloyatni ochishda xatolik!");
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: "/admission-types?only_active=1",
        });
        if (resp?.data?.status === "Success") {
          setTypes(resp?.data?.data);
        } else {
          message.error(resp?.data?.message);
        }
      } catch (e) {
        message.error("Qabul turlarini ochishda xatolik!!");
      }
    })();
  }, []);

  const handleRegions = async (event, type) => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: "/districts/" + event,
        });
        if (resp?.status === 200) {
          setDistricts(resp?.data?.data);
          if (!type) {
            form.setFieldsValue({
              district: null,
            });
          }
        }
      } catch (e) {
        message.error("Tumanlarni ochishda xatolik!");
      }
    })();
  };

  const handleDirectionsByFacultyId = async (id) => {
    setFacultyDirections(directions?.filter((item) => item?.faculty_id === id));
  };

  const uploadButton = (
    <div>
      <FaPlus />
      <div style={{ marginTop: 8 }}>{t("Yuklash")}</div>
    </div>
  );

  return (
    <div>
      <Spin spinning={loader}>
        <Form
          onFinishFailed={(e) => {
            console.log("Xatolik bordaaaaa", e);
          }}
          name="basic"
          form={form}
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          {/* General infos of bechalor, master, reprepearing */}
          <Row className="personel-infos p-1 p-md-3" gutter={[16, 8]}>
            <Col span={24} className="text-center">
              <h3 className="text-center">{t("Shaxsiy ma'lumotlar")}</h3>
            </Col>

            {/* fio */}
            <Col
              className="d-flex justify-content-center align-items-center"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
            >
              <div style={{ width: "100%", height: "100%" }}>
                <Form.Item
                  rules={[
                    { required: true, message: "Iltimos maydoni to'ldiring!" },
                  ]}
                  name={"photo"}
                  label={t("Hujjat topshiruvchining rasmi")}
                >
                  <Upload.Dragger
                    disabled={!disableName.includes("photo")}
                    accept=".jpeg,.png,.jpg"
                    listType="picture"
                    fileList={[]}
                    customRequest={dummyRequest}
                    onChange={(e) => {
                      console.log(e);
                      setFileUrls({
                        ...fileUrls,
                        img: [
                          {
                            id: "-1",
                            name: e?.file?.name,
                            status: "success",
                            url: window.URL.createObjectURL(
                              e?.file?.originFileObj
                            ),
                          },
                        ],
                      });
                    }}
                  >
                    {fileUrls.img?.length > 0 ? (
                      <img width="170px" src={fileUrls?.img[0]?.url} />
                    ) : (
                      uploadButton
                    )}
                  </Upload.Dragger>
                </Form.Item>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 16 }}
              lg={{ span: 16 }}
              xl={{ span: 16 }}
            >
              <Row gutter={24}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                  xl={{ span: 12 }}
                >
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Iltimos maydoni to'ldiring!",
                      },
                    ]}
                    name={"name"}
                    label={t("Ismi")}
                  >
                    <Input
                      disabled={!disableName.includes("name")}
                      placeholder={t("ismi")}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                  xl={{ span: 12 }}
                >
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Iltimos maydoni to'ldiring!",
                      },
                    ]}
                    name={"surname"}
                    label={t("Familiyasi")}
                  >
                    <Input
                      disabled={!disableName.includes("surname")}
                      placeholder={t("familyasi")}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                  xl={{ span: 12 }}
                >
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Iltimos maydoni to'ldiring!",
                      },
                    ]}
                    name={"middle_name"}
                    label={t("Otasining ismi")}
                  >
                    <Input
                      disabled={!disableName.includes("middle_name")}
                      placeholder={t("otasining ismi")}
                    />
                  </Form.Item>
                </Col>
                {/* Bith date */}
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                  xl={{ span: 12 }}
                >
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Iltimos maydoni to'ldiring!",
                      },
                    ]}
                    name={"birth_date"}
                    label={t("Tug'ulgan sanasi")}
                  >
                    <DatePicker
                      format={formatDate}
                      disabled={!disableName.includes("birth_date")}
                      style={{ width: "100%" }}
                      placeholder={t("tug'ulgan sanasi")}
                    />
                  </Form.Item>
                </Col>
                {/* Gender */}
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                  xl={{ span: 12 }}
                >
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Iltimos maydoni to'ldiring!",
                      },
                    ]}
                    name={"gender"}
                    label={t("Jinsi")}
                  >
                    <Select
                      disabled={!disableName.includes("gender")}
                      style={{ width: "100%" }}
                      placeholder={t("jinsi")}
                      onChange={handleChange}
                      allowClear
                    >
                      <Option value={1}>{t("Erkak")}</Option>
                      <Option value={0}>{t("Ayol")}</Option>
                    </Select>
                  </Form.Item>
                </Col>
                {/* Address */}
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                  xl={{ span: 12 }}
                >
                  {" "}
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Iltimos maydoni to'ldiring!",
                      },
                    ]}
                    name={"region"}
                    label={t("Viloyat")}
                  >
                    <Select
                      disabled={!disableName.includes("region")}
                      allowClear
                      style={{ width: "100%" }}
                      placeholder={t("viloyat")}
                      onChange={(e) => handleRegions(e)}
                    >
                      {regions?.map((item, i) => (
                        <Select.Option key={i} value={item?.id}>
                          {item?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item
                rules={[
                  { required: true, message: "Iltimos maydoni to'ldiring!" },
                ]}
                name={"district"}
                label={t("Tuman")}
              >
                <Select
                  disabled={!disableName.includes("district")}
                  allowClear
                  style={{ width: "100%" }}
                  placeholder={t("tuman")}
                >
                  {districts?.map((item, i) => (
                    <Select.Option key={i} value={"" + item?.id}>
                      {item?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item
                rules={[
                  { required: true, message: "Iltimos maydoni to'ldiring!" },
                ]}
                name={"address"}
                label={t("Manzil")}
                style={{ width: "100%" }}
              >
                <Input
                  disabled={!disableName.includes("address")}
                  placeholder={t("manzil")}
                />
              </Form.Item>
            </Col>
            {/* Contact */}
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              {" "}
              <Form.Item
                style={{ width: "100%" }}
                rules={[
                  { required: true, message: "Iltimos maydoni to'ldiring!" },
                ]}
                name={"telephone"}
                label={t("Telefon 1")}
              >
                <ReactInputMask
                  disabled={!disableName.includes("telephone")}
                  placeholder="+998("
                  className="ant-input"
                  mask="+\9\9\8(99) 999 99 99"
                  maskChar="_"
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              {" "}
              <Form.Item
                rules={[
                  { required: true, message: "Iltimos maydoni to'ldiring!" },
                ]}
                name={"telephone2"}
                label={t("Telefon 2")}
              >
                <ReactInputMask
                  disabled={!disableName.includes("telephone2")}
                  placeholder="+998("
                  className="ant-input"
                  mask="+\9\9\8(99) 999 99 99"
                  maskChar="_"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="personel-infos p-1 p-md-3" gutter={[16, 8]}>
            <Col span={24}>
              <h3 className="text-center">{t("Qabul turini tanlang!")}</h3>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item
                rules={[
                  { required: true, message: "Iltimos maydoni to'ldiring!" },
                ]}
                name={"admission_type_id"}
                label={t("Qabul turi")}
              >
                <Select
                  disabled={!disableName.includes("admission_type_id")}
                  onChange={(e) => setSelectedType(e)}
                  placeholder={t("qabul turini tanlang")}
                >
                  {types?.map((item, i) => (
                    <Select.Option value={item?.id}>
                      {[
                        i18next?.language === "uz"
                          ? item?.name_uz
                          : item?.name_ru,
                      ]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {/* Select Admission type */}

          {selectedType == 1 && (
            <>
              {/* Professional development */}
              <Row className="other-infos p-1 p-md-3" gutter={[16, 8]}>
                <Col span={24}>
                  <h3 className="text-center">{t("Malaka oshirish")}</h3>
                </Col>
                {/* Passport infos Col */}
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                  xl={{ span: 12 }}
                >
                  <h3 className="text-center">{t("Passport ma'lumotlar")}</h3>
                  <Row gutter={[16, 16]}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 12 }}
                      xl={{ span: 12 }}
                    >
                      <Row gutter={[8, 8]}>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}
                        >
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"passport_serie"}
                            label={t("Passport seriyasi")}
                          >
                            <ReactInputMask
                              disabled={!disableName.includes("passport_serie")}
                              placeholder="- -"
                              className="ant-input text-uppercase"
                              mask=""
                              maskChar="-"
                              maxLength={2}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}
                        >
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"passport_numbers"}
                            label={t("Passport raqami")}
                          >
                            <ReactInputMask
                              disabled={
                                !disableName.includes("passport_numbers")
                              }
                              placeholder="- - - - - - -"
                              className="ant-input"
                              mask="9999999"
                              maskChar="-"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}
                        >
                          {" "}
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"passport_jshr"}
                            label={t("Passport JSHIR")}
                          >
                            <ReactInputMask
                              disabled={!disableName.includes("passport_jshr")}
                              placeholder="- - - - - - - - - - - - - -"
                              className="ant-input"
                              mask="99999999999999"
                              maskChar="-"
                            />
                          </Form.Item>
                        </Col>
                        {/* Passport file */}
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}
                        >
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"passport_copy"}
                            label={t("Passport rangli nusxasi")}
                          >
                            <Upload.Dragger
                              disabled={!disableName.includes("passport_copy")}
                              accept=".doc,.docx,.png,.pdf"
                              listType="picture"
                              fileList={fileUrls?.passport}
                              onChange={(e) => {
                                console.log(e);
                                setFileUrls({
                                  ...fileUrls,
                                  passport: [
                                    {
                                      id: "-1",
                                      name: e?.file?.name,
                                      status: "success",
                                      url: window.URL.createObjectURL(
                                        e?.file?.originFileObj
                                      ),
                                    },
                                  ],
                                });
                              }}
                              customRequest={dummyRequest}
                            >
                              <Button
                                disabled={
                                  !disableName.includes("passport_copy")
                                }
                                icon={<BsUpload />}
                              >
                                {t("Yuklash")}
                              </Button>
                            </Upload.Dragger>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 12 }}
                      xl={{ span: 12 }}
                    >
                      <Row gutter={[8, 8]}>
                        <img
                          className="img-fluid mt-3"
                          src={jshrImage}
                          alt="networ error!"
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>

                {/* Diplom infos Col */}
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                  xl={{ span: 12 }}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <h3 className="text-center">{t("Ta'lim ma'lumotlar")}</h3>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}
                      xl={{ span: 24 }}
                    >
                      <Row gutter={[8, 8]}>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}
                        >
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"diploma_serie"}
                            label={t("Diplom seriyasi")}
                          >
                            <ReactInputMask
                              disabled={!disableName.includes("diploma_serie")}
                              className="ant-input text-uppercase"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}
                        >
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"diploma_number"}
                            label={t("Diplom raqami")}
                          >
                            <ReactInputMask
                              disabled={!disableName.includes("diploma_number")}
                              className="ant-input"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Iltimos maydoni to'ldiring!",
                          },
                        ]}
                        name={"diploma_given"}
                        label={t("Diplom berilgan sana")}
                      >
                        <DatePicker
                          format={formatDate}
                          style={{ width: "100%" }}
                          disabled={!disableName.includes("diploma_given")}
                          placeholder={t("sanani tanlang")}
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}
                      xl={{ span: 24 }}
                    >
                      <Row gutter={[8, 8]}>
                        {/* Diplom file */}
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}
                        >
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"diploma_copy"}
                            label={t("Diplom rangli nusxasi")}
                          >
                            <Upload.Dragger
                              disabled={!disableName.includes("diploma_copy")}
                              fileList={fileUrls?.diplom}
                              accept=".doc,.docx,.png,.pdf"
                              listType="picture"
                              customRequest={dummyRequest}
                              onChange={(e) => {
                                console.log(e);
                                setFileUrls({
                                  ...fileUrls,
                                  diplom: [
                                    {
                                      id: "-1",
                                      name: e?.file?.name,
                                      status: "success",
                                      url: window.URL.createObjectURL(
                                        e?.file?.originFileObj
                                      ),
                                    },
                                  ],
                                });
                              }}
                            >
                              <Button
                                disabled={!disableName.includes("diploma_copy")}
                                icon={<BsUpload />}
                              >
                                {t("Yuklash")}
                              </Button>
                            </Upload.Dragger>
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}
                        >
                          <Form.Item
                            rules={[
                              {
                                required: false,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"qayta_tayyorlov_sertifikat"}
                            label={t(
                              "Qayta tayyorlov sertifikati(agar mavjud bo'lsa)"
                            )}
                          >
                            <Upload.Dragger
                              disabled={!disableName.includes("diploma_copy")}
                              fileList={fileUrls?.sertificate}
                              accept=".doc,.docx,.png,.pdf"
                              listType="picture"
                              customRequest={dummyRequest}
                              onChange={(e) => {
                                console.log(e);
                                setFileUrls({
                                  ...fileUrls,
                                  sertificate: [
                                    {
                                      id: "-1",
                                      name: e?.file?.name,
                                      status: "success",
                                      url: window.URL.createObjectURL(
                                        e?.file?.originFileObj
                                      ),
                                    },
                                  ],
                                });
                              }}
                            >
                              <Button
                                disabled={!disableName.includes("diploma_copy")}
                                icon={<BsUpload />}
                              >
                                {t("Yuklash")}
                              </Button>
                            </Upload.Dragger>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {/* Education infos Row */}
              <Row className="education-infos p-1 p-md-3" gutter={[16, 8]}>
                <Col span={24}>
                  <h3 className="text-center">{t("Ta'lim ma'lumotlar")}</h3>
                </Col>
                {/* Education infos */}
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                  xl={{ span: 12 }}
                >
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Iltimos maydoni to'ldiring!",
                      },
                    ]}
                    name={"faculty_id"}
                    label={t("Ta`lim yo`nalishi")}
                  >
                    <Select
                      disabled={!disableName.includes("faculty_id")}
                      style={{ width: "100%" }}
                      placeholder={t("ta`lim yo`nalishi")}
                      onChange={(e) => {
                        _getFacultyItem(e);
                        form.setFieldsValue({
                          education_language_id: null,
                          education_type_id: null,
                        });
                      }}
                    >
                      {faculties
                        ?.filter(
                          (item) => item?.admission_type_id == selectedType
                        )
                        ?.map((item, i) => (
                          <Select.Option key={i} value={item?.id}>
                            {[
                              i18next.language === "uz"
                                ? item?.name_uz
                                : item?.name_ru,
                            ]}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Iltimos maydoni to'ldiring!",
                      },
                    ]}
                    name={"education_language_id"}
                    label={t("Ta'lim tili")}
                  >
                    <Select
                      disabled={!disableName.includes("education_language_id")}
                      placeholder={t("ta'lim tili")}
                    >
                      {facultyItem?.education_languages?.map((item, i) => (
                        <Select.Option key={i} value={item?.id}>
                          {[
                            i18next.language === "uz"
                              ? item?.name_uz
                              : item?.name_ru,
                          ]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Iltimos maydoni to'ldiring!",
                      },
                    ]}
                    name={"education_type_id"}
                    label={t("Ta'lim shakli")}
                  >
                    <Select
                      disabled={!disableName.includes("education_type_id")}
                      placeholder={t("ta'lim shakli")}
                    >
                      {facultyItem?.education_types?.map((item, i) => (
                        <Select.Option key={i} value={item?.id}>
                          {[
                            i18next.language === "uz"
                              ? item?.name_uz
                              : item?.name_ru,
                          ]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          {selectedType == 2 && (
            <>
              {/*  Reprepearing */}
              <Row className="other-infos p-1 p-md-3" gutter={[16, 8]}>
                <Col span={24}>
                  <h3 className="text-center">{t("Qayta tayyorlov")}</h3>
                </Col>
                {/* Passport infos Col */}
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                  xl={{ span: 12 }}
                >
                  <h3 className="text-center">{t("Passport ma'lumotlar")}</h3>
                  <Row gutter={[16, 16]}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 12 }}
                      xl={{ span: 12 }}
                    >
                      <Row gutter={[8, 8]}>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}
                        >
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"passport_serie"}
                            label={t("Passport seriyasi")}
                          >
                            <ReactInputMask
                              disabled={!disableName.includes("passport_serie")}
                              placeholder="- -"
                              className="ant-input text-uppercase"
                              mask=""
                              maskChar="-"
                              maxLength={2}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}
                        >
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"passport_numbers"}
                            label={t("Passport raqami")}
                          >
                            <ReactInputMask
                              disabled={
                                !disableName.includes("passport_numbers")
                              }
                              placeholder="- - - - - - -"
                              className="ant-input"
                              mask="9999999"
                              maskChar="-"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}
                        >
                          {" "}
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"passport_jshr"}
                            label={t("Passport JSHIR")}
                          >
                            <ReactInputMask
                              disabled={!disableName.includes("passport_jshr")}
                              placeholder="- - - - - - - - - - - - - -"
                              className="ant-input"
                              mask="99999999999999"
                              maskChar="-"
                            />
                          </Form.Item>
                        </Col>
                        {/* Passport file */}
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}
                        >
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"passport_copy"}
                            label={t("Passport rangli nusxasi")}
                          >
                            <Upload.Dragger
                              disabled={!disableName.includes("passport_copy")}
                              accept=".doc,.docx,.png,.pdf"
                              listType="picture"
                              fileList={fileUrls?.passport}
                              onChange={(e) => {
                                console.log(e);
                                setFileUrls({
                                  ...fileUrls,
                                  passport: [
                                    {
                                      id: "-1",
                                      name: e?.file?.name,
                                      status: "success",
                                      url: window.URL.createObjectURL(
                                        e?.file?.originFileObj
                                      ),
                                    },
                                  ],
                                });
                              }}
                              customRequest={dummyRequest}
                            >
                              <Button
                                disabled={
                                  !disableName.includes("passport_copy")
                                }
                                icon={<BsUpload />}
                              >
                                {t("Yuklash")}
                              </Button>
                            </Upload.Dragger>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 12 }}
                      xl={{ span: 12 }}
                    >
                      <Row gutter={[8, 8]}>
                        <img
                          className="img-fluid mt-3"
                          src={jshrImage}
                          alt="networ error!"
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>

                {/* Diplom infos Col */}
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                  xl={{ span: 12 }}
                >
                  <h3 className="text-center">{t("Ta'lim ma'lumotlar")}</h3>
                  <Row gutter={[16, 16]}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 12 }}
                      xl={{ span: 12 }}
                    >
                      <Row gutter={[8, 8]}>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 24 }}
                        >
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"diploma_serie"}
                            label={t("Diplom seriyasi")}
                          >
                            <ReactInputMask
                              disabled={!disableName.includes("diploma_serie")}
                              className="ant-input text-uppercase"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 24 }}
                        >
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"diploma_number"}
                            label={t("Diplom raqami")}
                          >
                            <ReactInputMask
                              disabled={!disableName.includes("diploma_number")}
                              className="ant-input"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 12 }}
                      xl={{ span: 12 }}
                    >
                      <Row gutter={[8, 8]}>
                        <Col span={24}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"diploma_given"}
                            label={t("Diplom berilgan sana")}
                          >
                            <DatePicker
                              format={formatDate}
                              style={{ width: "100%" }}
                              disabled={!disableName.includes("diploma_given")}
                              placeholder={t("sanani tanlang")}
                            />
                          </Form.Item>
                        </Col>
                        {/* Diplom file */}
                        <Col span={24}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"diploma_copy"}
                            label={t("Diplom va ilova rangli nusxasi")}
                          >
                            <Upload.Dragger
                              disabled={!disableName.includes("diploma_copy")}
                              fileList={fileUrls?.diplom}
                              accept=".doc,.docx,.png,.pdf"
                              listType="picture"
                              customRequest={dummyRequest}
                              onChange={(e) => {
                                console.log(e);
                                setFileUrls({
                                  ...fileUrls,
                                  diplom: [
                                    {
                                      id: "-1",
                                      name: e?.file?.name,
                                      status: "success",
                                      url: window.URL.createObjectURL(
                                        e?.file?.originFileObj
                                      ),
                                    },
                                  ],
                                });
                              }}
                            >
                              <Button
                                disabled={!disableName.includes("diploma_copy")}
                                icon={<BsUpload />}
                              >
                                {t("Yuklash")}
                              </Button>
                            </Upload.Dragger>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {/* Education infos Row */}
              <Row className="education-infos p-1 p-md-3" gutter={[16, 8]}>
                <Col span={24}>
                  <h3 className="text-center">{t("Ta'lim ma'lumotlar")}</h3>
                </Col>
                {/* Education infos */}
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                  xl={{ span: 12 }}
                >
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Iltimos maydoni to'ldiring!",
                      },
                    ]}
                    name={"faculty_id"}
                    label={t("Ta`lim yo`nalishi")}
                  >
                    <Select
                      disabled={!disableName.includes("faculty_id")}
                      style={{ width: "100%" }}
                      placeholder={t("ta`lim yo`nalishi")}
                      onChange={(e) => {
                        _getFacultyItem(e);
                        form.setFieldsValue({
                          education_language_id: null,
                          education_type_id: null,
                        });
                      }}
                    >
                      {faculties
                        ?.filter(
                          (item) => item?.admission_type_id == selectedType
                        )
                        ?.map((item, i) => (
                          <Select.Option key={i} value={item?.id}>
                            {[
                              i18next.language === "uz"
                                ? item?.name_uz
                                : item?.name_ru,
                            ]}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Iltimos maydoni to'ldiring!",
                      },
                    ]}
                    name={"education_language_id"}
                    label={t("Ta'lim tili")}
                  >
                    <Select
                      disabled={!disableName.includes("education_language_id")}
                      placeholder={t("ta'lim tili")}
                    >
                      {facultyItem?.education_languages?.map((item, i) => (
                        <Select.Option key={i} value={item?.id}>
                          {[i18next === "uz" ? item?.name_uz : item?.name_ru]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Iltimos maydoni to'ldiring!",
                      },
                    ]}
                    name={"education_type_id"}
                    label={t("Ta'lim shakli")}
                  >
                    <Select
                      disabled={!disableName.includes("education_type_id")}
                      placeholder={t("ta'lim shakli")}
                    >
                      {facultyItem?.education_types?.map((item, i) => (
                        <Select.Option key={i} value={item?.id}>
                          {[
                            i18next.language === "uz"
                              ? item?.name_uz
                              : item?.name_ru,
                          ]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          {selectedType == 3 && (
            <>
              {/* Passport and diplom infos Row */}
              <Row className="other-infos p-1 p-md-3" gutter={[16, 8]}>
                <Col span={24}>
                  <h3 className="text-center">{t("Bakalavr")}</h3>
                </Col>
                {/* Passport infos Col */}
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                  xl={{ span: 12 }}
                >
                  <h3 className="text-center">{t("Passport ma'lumotlar")}</h3>
                  <Row gutter={[16, 16]}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 12 }}
                      xl={{ span: 12 }}
                    >
                      <Row gutter={[8, 8]}>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}
                        >
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"passport_serie"}
                            label={t("Passport seriyasi")}
                          >
                            <ReactInputMask
                              disabled={!disableName.includes("passport_serie")}
                              placeholder="- -"
                              className="ant-input text-uppercase"
                              mask=""
                              maskChar="-"
                              maxLength={2}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}
                        >
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"passport_numbers"}
                            label={t("Passport raqami")}
                          >
                            <ReactInputMask
                              disabled={
                                !disableName.includes("passport_numbers")
                              }
                              placeholder="- - - - - - -"
                              className="ant-input"
                              mask="9999999"
                              maskChar="-"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}
                        >
                          {" "}
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"passport_jshr"}
                            label={t("Passport JSHIR")}
                          >
                            <ReactInputMask
                              disabled={!disableName.includes("passport_jshr")}
                              placeholder="- - - - - - - - - - - - - -"
                              className="ant-input"
                              mask="99999999999999"
                              maskChar="-"
                            />
                          </Form.Item>
                        </Col>
                        {/* Passport file */}
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}
                        >
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"passport_copy"}
                            label={t("Passport rangli nusxasi")}
                          >
                            <Upload.Dragger
                              disabled={!disableName.includes("passport_copy")}
                              accept=".doc,.docx,.png,.pdf"
                              listType="picture"
                              fileList={fileUrls?.passport}
                              onChange={(e) => {
                                console.log(e);
                                setFileUrls({
                                  ...fileUrls,
                                  passport: [
                                    {
                                      id: "-1",
                                      name: e?.file?.name,
                                      status: "success",
                                      url: window.URL.createObjectURL(
                                        e?.file?.originFileObj
                                      ),
                                    },
                                  ],
                                });
                              }}
                              customRequest={dummyRequest}
                            >
                              <Button
                                disabled={
                                  !disableName.includes("passport_copy")
                                }
                                icon={<BsUpload />}
                              >
                                {t("Yuklash")}
                              </Button>
                            </Upload.Dragger>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 12 }}
                      xl={{ span: 12 }}
                    >
                      <Row gutter={[8, 8]}>
                        <img
                          className="img-fluid mt-3"
                          src={jshrImage}
                          alt="networ error!"
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>

                {/* Diplom infos Col */}
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                  xl={{ span: 12 }}
                >
                  <h3 className="text-center">{t("Ta'lim ma'lumotlar")}</h3>
                  <Row gutter={[16, 16]}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 12 }}
                      xl={{ span: 12 }}
                    >
                      <Row gutter={[8, 8]}>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 24 }}
                        >
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"diploma_serie"}
                            label={t("Diplom/Attestat seriyasi")}
                          >
                            <ReactInputMask
                              disabled={!disableName.includes("diploma_serie")}
                              className="ant-input text-uppercase"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 24 }}
                        >
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"diploma_number"}
                            label={t("Diplom/Attestat raqam")}
                          >
                            <ReactInputMask
                              disabled={!disableName.includes("diploma_number")}
                              className="ant-input"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 12 }}
                      xl={{ span: 12 }}
                    >
                      <Row gutter={[8, 8]}>
                        <Col span={24}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"diploma_given"}
                            label={t("Diplom/Attestat berilgan sana")}
                          >
                            <DatePicker
                              format={formatDate}
                              style={{ width: "100%" }}
                              disabled={!disableName.includes("diploma_given")}
                              placeholder={t("sanani tanlang")}
                            />
                          </Form.Item>
                        </Col>
                        {/* Diplom file */}
                        <Col span={24}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"diploma_copy"}
                            label={t("Diplom/Attestat va ilova nusxasi")}
                          >
                            <Upload.Dragger
                              disabled={!disableName.includes("diploma_copy")}
                              fileList={fileUrls?.diplom}
                              accept=".doc,.docx,.png,.pdf"
                              listType="picture"
                              customRequest={dummyRequest}
                              onChange={(e) => {
                                console.log(e);
                                setFileUrls({
                                  ...fileUrls,
                                  diplom: [
                                    {
                                      id: "-1",
                                      name: e?.file?.name,
                                      status: "success",
                                      url: window.URL.createObjectURL(
                                        e?.file?.originFileObj
                                      ),
                                    },
                                  ],
                                });
                              }}
                            >
                              <Button
                                disabled={!disableName.includes("diploma_copy")}
                                icon={<BsUpload />}
                              >
                                {t("Yuklash")}
                              </Button>
                            </Upload.Dragger>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* Education infos Row */}
              <Row className="education-infos p-1 p-md-3" gutter={[16, 8]}>
                <Col span={24}>
                  <h3 className="text-center">{t("Ta'lim ma'lumotlar")}</h3>
                </Col>
                {/* Education infos */}
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                  xl={{ span: 12 }}
                >
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Iltimos maydoni to'ldiring!",
                      },
                    ]}
                    name={"faculty_id"}
                    label={t("Ta`lim yo`nalishi")}
                  >
                    <Select
                      disabled={!disableName.includes("faculty_id")}
                      style={{ width: "100%" }}
                      placeholder={t("ta`lim yo`nalishi")}
                      onChange={(e) => {
                        _getFacultyItem(e);
                        form.setFieldsValue({
                          education_language_id: null,
                          education_type_id: null,
                        });
                      }}
                    >
                      {faculties
                        ?.filter(
                          (item) => item?.admission_type_id == selectedType
                        )
                        ?.map((item, i) => (
                          <Select.Option key={i} value={item?.id}>
                            {[
                              i18next.language === "uz"
                                ? item?.name_uz
                                : item?.name_ru,
                            ]}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Iltimos maydoni to'ldiring!",
                      },
                    ]}
                    name={"education_language_id"}
                    label={t("Ta'lim tili")}
                  >
                    <Select
                      disabled={!disableName.includes("education_language_id")}
                      placeholder={t("ta'lim tili")}
                    >
                      {facultyItem?.education_languages?.map((item, i) => (
                        <Select.Option key={i} value={item?.id}>
                          {[
                            i18next.language === "uz"
                              ? item?.name_uz
                              : item?.name_ru,
                          ]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Iltimos maydoni to'ldiring!",
                      },
                    ]}
                    name={"education_type_id"}
                    label={t("Ta'lim shakli")}
                  >
                    <Select
                      disabled={!disableName.includes("education_type_id")}
                      placeholder={t("ta'lim shakli")}
                    >
                      {facultyItem?.education_types?.map((item, i) => (
                        <Select.Option key={i} value={item?.id}>
                          {[
                            i18next.language === "uz"
                              ? item?.name_uz
                              : item?.name_ru,
                          ]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          {selectedType == 4 && (
            <>
              {/* Passport and diplom infos Row */}
              <Row className="other-infos p-1 p-md-3" gutter={[16, 8]}>
                <Col span={24}>
                  <h3 className="text-center">{t("Magistratura")}</h3>
                </Col>
                {/* Passport infos Col */}
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                  xl={{ span: 12 }}
                >
                  <h3 className="text-center">{t("Passport ma'lumotlar")}</h3>
                  <Row gutter={[16, 16]}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 12 }}
                      xl={{ span: 12 }}
                    >
                      <Row gutter={[8, 8]}>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}
                        >
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"passport_serie"}
                            label={t("Passport seriyasi")}
                          >
                            <ReactInputMask
                              disabled={!disableName.includes("passport_serie")}
                              placeholder="- -"
                              className="ant-input text-uppercase"
                              mask=""
                              maskChar="-"
                              maxLength={2}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}
                        >
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"passport_numbers"}
                            label={t("Passport raqami")}
                          >
                            <ReactInputMask
                              disabled={
                                !disableName.includes("passport_numbers")
                              }
                              placeholder="- - - - - - -"
                              className="ant-input"
                              mask="9999999"
                              maskChar="-"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}
                        >
                          {" "}
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"passport_jshr"}
                            label={t("Passport JSHIR")}
                          >
                            <ReactInputMask
                              disabled={!disableName.includes("passport_jshr")}
                              placeholder="- - - - - - - - - - - - - -"
                              className="ant-input"
                              mask="99999999999999"
                              maskChar="-"
                            />
                          </Form.Item>
                        </Col>
                        {/* Passport file */}
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}
                        >
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"passport_copy"}
                            label={t("Passport rangli nusxasi")}
                          >
                            <Upload.Dragger
                              disabled={!disableName.includes("passport_copy")}
                              accept=".doc,.docx,.png,.pdf"
                              listType="picture"
                              fileList={fileUrls?.passport}
                              onChange={(e) => {
                                console.log(e);
                                setFileUrls({
                                  ...fileUrls,
                                  passport: [
                                    {
                                      id: "-1",
                                      name: e?.file?.name,
                                      status: "success",
                                      url: window.URL.createObjectURL(
                                        e?.file?.originFileObj
                                      ),
                                    },
                                  ],
                                });
                              }}
                              customRequest={dummyRequest}
                            >
                              <Button
                                disabled={
                                  !disableName.includes("passport_copy")
                                }
                                icon={<BsUpload />}
                              >
                                {t("Yuklash")}
                              </Button>
                            </Upload.Dragger>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 12 }}
                      xl={{ span: 12 }}
                    >
                      <Row gutter={[8, 8]}>
                        <img
                          className="img-fluid mt-3"
                          src={jshrImage}
                          alt="networ error!"
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>

                {/* Diplom infos Col */}
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                  xl={{ span: 12 }}
                >
                  <h3 className="text-center">{t("Ta'lim ma'lumotlar")}</h3>
                  <Row gutter={[16, 16]}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 12 }}
                      xl={{ span: 12 }}
                    >
                      <Row gutter={[8, 8]}>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 24 }}
                        >
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"diploma_serie"}
                            label={t("Diplom seriyasi")}
                          >
                            <ReactInputMask
                              disabled={!disableName.includes("diploma_serie")}
                              className="ant-input text-uppercase"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 24 }}
                        >
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"diploma_number"}
                            label={t("Diplom raqami")}
                          >
                            <ReactInputMask
                              disabled={!disableName.includes("diploma_number")}
                              className="ant-input"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 12 }}
                      xl={{ span: 12 }}
                    >
                      <Row gutter={[8, 8]}>
                        <Col span={24}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"diploma_given"}
                            label={t("Diplom berilgan sana")}
                          >
                            <DatePicker
                              format={formatDate}
                              style={{ width: "100%" }}
                              disabled={!disableName.includes("diploma_given")}
                              placeholder={t("sanani tanlang")}
                            />
                          </Form.Item>
                        </Col>
                        {/* Diplom file */}
                        <Col span={24}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Iltimos maydoni to'ldiring!",
                              },
                            ]}
                            name={"diploma_copy"}
                            label={t("Diplom nusxasi")}
                          >
                            <Upload.Dragger
                              disabled={!disableName.includes("diploma_copy")}
                              fileList={fileUrls?.diplom}
                              accept=".doc,.docx,.png,.pdf"
                              listType="picture"
                              customRequest={dummyRequest}
                              onChange={(e) => {
                                console.log(e);
                                setFileUrls({
                                  ...fileUrls,
                                  diplom: [
                                    {
                                      id: "-1",
                                      name: e?.file?.name,
                                      status: "success",
                                      url: window.URL.createObjectURL(
                                        e?.file?.originFileObj
                                      ),
                                    },
                                  ],
                                });
                              }}
                            >
                              <Button
                                disabled={!disableName.includes("diploma_copy")}
                                icon={<BsUpload />}
                              >
                                {t("Yuklash")}
                              </Button>
                            </Upload.Dragger>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* Education infos Row */}
              <Row className="education-infos p-1 p-md-3" gutter={[16, 8]}>
                <Col span={24}>
                  <h3 className="text-center">{t("Ta'lim ma'lumotlar")}</h3>
                </Col>
                {/* Education infos */}
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                  xl={{ span: 12 }}
                >
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Iltimos maydoni to'ldiring!",
                      },
                    ]}
                    name={"faculty_id"}
                    label={t("Magistratura mutaxasisliklari")}
                  >
                    <Select
                      disabled={!disableName.includes("faculty_id")}
                      style={{ width: "100%" }}
                      placeholder={t("magistratura mutaxasisliklari")}
                      onChange={(e) => {
                        _getFacultyItem(e);
                        handleDirectionsByFacultyId(e);
                        form.setFieldsValue({
                          education_language_id: null,
                          education_type_id: null,
                          // direction_id: null,
                        });
                      }}
                    >
                      {faculties
                        ?.filter(
                          (item) => item?.admission_type_id == selectedType
                        )
                        ?.map((item, i) => (
                          <Select.Option key={i} value={item?.id}>
                            {[
                              i18next.language === "uz"
                                ? item?.name_uz
                                : item?.name_ru,
                            ]}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                {/* Hidden directions */}
                {/* <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                  xl={{ span: 6 }}
                  className="d-none"
                >
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Iltimos maydoni to'ldiring!",
                      },
                    ]}
                    name={"direction_id"}
                    label={"Direction"}
                  >
                    <Select
                      disabled={!disableName.includes("direction_id")}
                      style={{ width: "100%" }}
                      placeholder="Direction"
                      onChange={(e) => {
                        form.setFieldsValue({
                          education_language_id: null,
                          education_type_id: null,
                        });
                      }}
                    >
                      {facultyDirections?.map((item, i) => (
                        <Select.Option key={i} value={item?.id}>
                          {i18next.language === "uz"
                            ? item?.name_uz
                            : item?.name_ru}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col> */}

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Iltimos maydoni to'ldiring!",
                      },
                    ]}
                    name={"education_language_id"}
                    label={t("Ta'lim tili")}
                  >
                    <Select
                      disabled={!disableName.includes("education_language_id")}
                      placeholder={t("ta'lim tili")}
                    >
                      {facultyItem?.education_languages?.map((item, i) => (
                        <Select.Option key={i} value={item?.id}>
                          {[
                            i18next.language === "uz"
                              ? item?.name_uz
                              : item?.name_ru,
                          ]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Iltimos maydoni to'ldiring!",
                      },
                    ]}
                    name={"education_type_id"}
                    label={t("Ta'lim shakli")}
                  >
                    <Select
                      disabled={!disableName.includes("education_type_id")}
                      placeholder={t("ta'lim shakli")}
                    >
                      {facultyItem?.education_types?.map((item, i) => (
                        <Select.Option key={i} value={item?.id}>
                          {[
                            i18next.language === "uz"
                              ? item?.name_uz
                              : item?.name_ru,
                          ]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          {/* Send and clear buttons */}
          <Row>
            <Col
              span={24}
              style={{
                textAlign: "right",
              }}
            >
              <Button
                htmlType="reset"
                className="m-1 btn-danger"
                type="primary"
              >
                {t("O'chirish")}
              </Button>
              <Button
                style={{
                  margin: "0 8px",
                }}
                htmlType="submit"
                className="m-1"
                type="primary"
              >
                {t("Saqlash")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
};

export default ApplyDocs;
