import {
  Button,
  Col,
  Divider,
  Form,
  Image,
  Input,
  message,
  Radio,
  Row,
} from "antd";
import React from "react";
import { useState, useEffect } from "react";
import instance from "../../../api/axios";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { GrView } from "react-icons/gr";
import { BiArrowBack } from "react-icons/bi";
import { Link, useHistory, useLocation } from "react-router-dom";
import "../checker.scss";
import { FILE_URL } from "../../../api/constants";
import { useTranslation } from "react-i18next";

const { TextArea } = Input;

const ApplicantViewer = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [applications, setApplications] = useState({});
  const [errors, setErrors] = useState("");
  const location = useLocation()?.pathname;
  let a = location?.split("/");
  const [fileUrls, setFileUrls] = useState();
  const { t } = useTranslation();
  const [disableName, setDisableName] = useState([
    "name",
    "surname",
    "middle_name",
    "birth_date",
    "gender",
    "region",
    "district",
    "address",
    "telephone",
    "telephone2",
    "photo",
    "passport_serie",
    "passport_numbers",
    "passport_jshr",
    "passport_copy",
    "diploma_serie",
    "diploma_number",
    "diploma_copy",
    "faculty_id",
    "education_type_id",
    "education_language_id",
  ]);

  // change status function
  const changeStatus = async () => {
    try {
      const resp = await instance({
        method: "post",
        url: `/application/status/change/${a[a.length - 1]}?status=3`,
      });

      if (resp?.data?.status === "Success") {
        message.success(resp?.data?.message);
        history.push("/checker");
      }
    } catch (e) {
      message.error(e?.response?.data?.message);
    }
  };

  const onFinish = async (values) => {
    const data = new FormData();
    console.log(values);

    let ac = [];
    Object.entries(values)?.forEach(([key, value]) => {
      if (key.startsWith("error")) {
        let b = key.split("-");
        ac = [
          ...ac,
          {
            name: b[1],
            comment: value,
          },
        ];
      }
    });

    console.log("errors-> ", a);
    let id = a[a.length - 1];
    console.log(id);
    console.log(ac);
    data.append("errors", JSON.stringify(ac));
    if (ac.length > 0) {
      try {
        const resp = await instance({
          method: "post",
          url: "/application/feedback/" + id,
          data: data,
        });
        history.push("/checker");
      } catch (e) {
        message?.error("Xatoliklarni yuborishda xatolik!");
      }
    } else {
      message.error("Kamida bitta noto'g'ri maydonni belgilang!");
    }
  };

  // get applications data
  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url:
            "/applications/" +
            a[a.length - 1] +
            "?expand=region,district,education_type,education_language,faculty,feedbacks_latest",
        });
        if (resp?.data?.status === "Success") {
          const data = resp?.data?.data;
          console.log("data---------> ", data);
          form.setFieldsValue({
            ...data,
            gender_name: data?.gender === 0 ? `${t("Ayol")}` : `${t("Erlak")}`,
            region_name: data?.region?.name,
            district_name: data?.district?.name,
            faculty_name: data?.faculty?.name_uz,
            education_language_name: data?.education_language?.name_uz,
            education_type_name: data?.education_type?.name_uz,
          });
          setFileUrls({
            image_url: data?.folder + "/" + data?.photo,
            passport_url: data?.folder + "/" + data?.passport_copy,
            diplom_url: data?.folder + "/" + data?.diploma_copy,
            qayta_tayyorlov_sertifikat: data?.qayta_tayyorlov_sertifikat
              ? data?.folder + "/" + data?.qayta_tayyorlov_sertifikat
              : undefined,
          });
          if (data?.feedbacks_latest) {
            let errors = [];
            data?.feedbacks_latest?.map((item) => {
              errors = [
                ...errors,
                {
                  name: [item?.column_name],
                  errors: [item?.feedback],
                },
              ];
            });
            form.setFields(errors);
          }
        }
      } catch (e) {
        message.error("Hujjatni ochishda xatolik!");
      }
    })();
  }, []);

  //   JSX
  return (
    <Form name="basic" form={form} layout="vertical" onFinish={onFinish}>
      <Row gutter={12}>
        <Col span={24}>
          <Link className="fs-6 text-dark" type="link" onClick={() => history.goBack()}>
            <BiArrowBack /><span className="mx-2">{t("orqaga")}</span>
          </Link>
        </Col>
        <Col className="" span={24}>
          <Divider orientation="center">Ma'lumotlarni tekshirish</Divider>
        </Col>

        {/* fio */}
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          xl={{ span: 6 }}
        >
          <Row gutter={24}>
            <Col span={24} className="d-flex align-items-center">
              <div
                style={{ width: "100%" }}
                className="d-flex align-items-center"
              >
                <Form.Item
                  style={{ width: "100%" }}
                  name={"name"}
                  label={t("Ismi")}
                >
                  <Input readOnly />
                </Form.Item>

                <Button
                  onClick={() => {
                    if (!errors?.includes("error-name")) {
                      setErrors([...errors, "error-name"]);
                    } else {
                      setErrors([
                        ...errors?.filter((item) => item !== "error-name"),
                      ]);
                    }
                  }}
                  danger
                  className="ms-2 btn-md"
                  style={{ marginTop: "7px" }}
                >
                  <AiOutlineCloseCircle />
                </Button>
              </div>
            </Col>
          </Row>
          {errors.includes("error-name") && (
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item name={"error-name"} label={"Comment for name"}>
                  <TextArea maxLength={100} showCount />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>

        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          xl={{ span: 6 }}
        >
          <Row gutter={24}>
            <Col span={24} className="d-flex align-items-center">
              <div
                style={{ width: "100%" }}
                className="d-flex align-items-center"
              >
                <Form.Item
                  style={{ width: "100%" }}
                  name={"surname"}
                  label={t("Familyasi")}
                >
                  <Input readOnly />
                </Form.Item>

                <Button
                  onClick={() => {
                    if (!errors?.includes("error-surname")) {
                      setErrors([...errors, "error-surname"]);
                    } else {
                      setErrors([
                        ...errors?.filter((item) => item !== "error-surname"),
                      ]);
                    }
                  }}
                  danger
                  className="ms-2 btn-md"
                  style={{ marginTop: "7px" }}
                >
                  <AiOutlineCloseCircle />
                </Button>
              </div>
            </Col>
          </Row>
          {errors.includes("error-surname") && (
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item name={"error-surname"} label={"Comment for surname"}>
                  <TextArea maxLength={100} showCount />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>

        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          xl={{ span: 6 }}
        >
          <Row gutter={24}>
            <Col span={24} className="d-flex align-items-center">
              <div
                style={{ width: "100%" }}
                className="d-flex align-items-center"
              >
                <Form.Item
                  style={{ width: "100%" }}
                  name={"middle_name"}
                  label={t("Otasining ismi")}
                >
                  <Input readOnly />
                </Form.Item>

                <Button
                  onClick={() => {
                    if (!errors?.includes("error-middle_name")) {
                      setErrors([...errors, "error-middle_name"]);
                    } else {
                      setErrors([
                        ...errors?.filter(
                          (item) => item !== "error-middle_name"
                        ),
                      ]);
                    }
                  }}
                  danger
                  className="ms-2 btn-md"
                  style={{ marginTop: "7px" }}
                >
                  <AiOutlineCloseCircle />
                </Button>
              </div>
            </Col>
          </Row>
          {errors.includes("error-middle_name") && (
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name={"error-middle_name"}
                  label={"Comment for middle name"}
                >
                  <TextArea maxLength={100} showCount />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>

        {/* bith date */}
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          xl={{ span: 6 }}
        >
          <Row gutter={24}>
            <Col span={24} className="d-flex align-items-center">
              <div
                style={{ width: "100%" }}
                className="d-flex align-items-center"
              >
                <Form.Item
                  style={{ width: "100%" }}
                  name={"birthdate"}
                  label={t("Tug'ulgan sana")}
                >
                  <Input readOnly />
                </Form.Item>

                <Button
                  onClick={() => {
                    if (!errors?.includes("error-birth_date")) {
                      setErrors([...errors, "error-birth_date"]);
                    } else {
                      setErrors([
                        ...errors?.filter(
                          (item) => item !== "error-birth_date"
                        ),
                      ]);
                    }
                  }}
                  danger
                  className="ms-2 btn-md"
                  style={{ marginTop: "7px" }}
                >
                  <AiOutlineCloseCircle />
                </Button>
              </div>
            </Col>
          </Row>
          {errors.includes("error-birth_date") && (
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name={"error-birth_date"}
                  label={"Comment for Birth date"}
                >
                  <TextArea maxLength={100} showCount />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>

        {/* Gender */}
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          xl={{ span: 6 }}
        >
          <Row gutter={24}>
            <Col span={24} className="d-flex align-items-center">
              <div
                style={{ width: "100%" }}
                className="d-flex align-items-center"
              >
                <Form.Item
                  style={{ width: "100%" }}
                  name={"gender_name"}
                  label={t("Jinsi")}
                >
                  <Input readOnly />
                </Form.Item>

                <Button
                  onClick={() => {
                    if (!errors?.includes("error-gender")) {
                      setErrors([...errors, "error-gender"]);
                    } else {
                      setErrors([
                        ...errors?.filter((item) => item !== "error-gender"),
                      ]);
                    }
                  }}
                  danger
                  className="ms-2 btn-md"
                  style={{ marginTop: "7px" }}
                >
                  <AiOutlineCloseCircle />
                </Button>
              </div>
            </Col>
          </Row>
          {errors.includes("error-gender") && (
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item name={"error-gender"} label={"Comment for gender"}>
                  <TextArea maxLength={100} showCount />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>

        {/* Address */}
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          xl={{ span: 6 }}
        >
          <Row gutter={24}>
            <Col span={24} className="d-flex align-items-center">
              <div
                style={{ width: "100%" }}
                className="d-flex align-items-center"
              >
                <Form.Item
                  style={{ width: "100%" }}
                  name={"region_name"}
                  label={t("Viloyat")}
                >
                  <Input readOnly />
                </Form.Item>

                <Button
                  onClick={() => {
                    if (!errors?.includes("error-regions")) {
                      setErrors([...errors, "error-regions"]);
                    } else {
                      setErrors([
                        ...errors?.filter((item) => item !== "error-regions"),
                      ]);
                    }
                  }}
                  danger
                  className="ms-2 btn-md"
                  style={{ marginTop: "7px" }}
                >
                  <AiOutlineCloseCircle />
                </Button>
              </div>
            </Col>
          </Row>
          {errors.includes("error-regions") && (
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item name={"error-region"} label={"Comment for regions"}>
                  <TextArea maxLength={100} showCount />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>

        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          xl={{ span: 6 }}
        >
          <Row gutter={24}>
            <Col span={24} className="d-flex align-items-center">
              <div
                style={{ width: "100%" }}
                className="d-flex align-items-center"
              >
                <Form.Item
                  style={{ width: "100%" }}
                  name={"district_name"}
                  label={t("Tuman")}
                >
                  <Input readOnly />
                </Form.Item>

                <Button
                  onClick={() => {
                    if (!errors?.includes("error-district")) {
                      setErrors([...errors, "error-district"]);
                    } else {
                      setErrors([
                        ...errors?.filter((item) => item !== "error-district"),
                      ]);
                    }
                  }}
                  danger
                  className="ms-2 btn-md"
                  style={{ marginTop: "7px" }}
                >
                  <AiOutlineCloseCircle />
                </Button>
              </div>
            </Col>
          </Row>
          {errors.includes("error-district") && (
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name={"error-district"}
                  label={"Comment for district"}
                >
                  <TextArea maxLength={100} showCount />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>

        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          xl={{ span: 6 }}
        >
          <Row gutter={24}>
            <Col span={24} className="d-flex align-items-center">
              <div
                style={{ width: "100%" }}
                className="d-flex align-items-center"
              >
                <Form.Item
                  style={{ width: "100%" }}
                  name={"address"}
                  label={t("Manzil")}
                >
                  <Input readOnly />
                </Form.Item>

                <Button
                  onClick={() => {
                    if (!errors?.includes("error-address")) {
                      setErrors([...errors, "error-address"]);
                    } else {
                      setErrors([
                        ...errors?.filter((item) => item !== "error-address"),
                      ]);
                    }
                  }}
                  danger
                  className="ms-2 btn-md"
                  style={{ marginTop: "7px" }}
                >
                  <AiOutlineCloseCircle />
                </Button>
              </div>
            </Col>
          </Row>
          {errors.includes("error-address") && (
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item name={"error-address"} label={"Comment for address"}>
                  <TextArea maxLength={100} showCount />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>

        {/* Contacts  */}
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          xl={{ span: 6 }}
        >
          <Row gutter={24}>
            <Col span={24} className="d-flex align-items-center">
              <div
                style={{ width: "100%" }}
                className="d-flex align-items-center"
              >
                <Form.Item
                  style={{ width: "100%" }}
                  name={"telephone"}
                  label={t("Telefon 1")}
                >
                  <Input readOnly />
                </Form.Item>

                <Button
                  onClick={() => {
                    if (!errors?.includes("error-telephone")) {
                      setErrors([...errors, "error-telephone"]);
                    } else {
                      setErrors([
                        ...errors?.filter((item) => item !== "error-telephone"),
                      ]);
                    }
                  }}
                  danger
                  className="ms-2 btn-md"
                  style={{ marginTop: "7px" }}
                >
                  <AiOutlineCloseCircle />
                </Button>
              </div>
            </Col>
          </Row>
          {errors.includes("error-telephone") && (
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name={"error-telephone"}
                  label={"Comment for telephone"}
                >
                  <TextArea maxLength={100} showCount />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>

        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          xl={{ span: 6 }}
        >
          <Row gutter={24}>
            <Col span={24} className="d-flex align-items-center">
              <div
                style={{ width: "100%" }}
                className="d-flex align-items-center"
              >
                <Form.Item
                  style={{ width: "100%" }}
                  name={"telephone2"}
                  label={t("Telefon 2")}
                >
                  <Input readOnly />
                </Form.Item>

                <Button
                  onClick={() => {
                    if (!errors?.includes("error-telephone2")) {
                      setErrors([...errors, "error-telephone2"]);
                    } else {
                      setErrors([
                        ...errors?.filter(
                          (item) => item !== "error-telephone2"
                        ),
                      ]);
                    }
                  }}
                  danger
                  className="ms-2 btn-md"
                  style={{ marginTop: "7px" }}
                >
                  <AiOutlineCloseCircle />
                </Button>
              </div>
            </Col>
          </Row>
          {errors.includes("error-telephone2") && (
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name={"error-telephone2"}
                  label={"Comment for telephone 2"}
                >
                  <TextArea maxLength={100} showCount />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>

        {/* Passport infos */}
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          xl={{ span: 6 }}
        >
          <Row gutter={24}>
            <Col span={24} className="d-flex align-items-center">
              <div
                style={{ width: "100%" }}
                className="d-flex align-items-center"
              >
                <Form.Item
                  style={{ width: "100%" }}
                  name={"passport_serie"}
                  label={t("Passport seriyasi")}
                >
                  <Input readOnly />
                </Form.Item>

                <Button
                  onClick={() => {
                    if (!errors?.includes("error-passport_serie")) {
                      setErrors([...errors, "error-passport_serie"]);
                    } else {
                      setErrors([
                        ...errors?.filter(
                          (item) => item !== "error-passport_serie"
                        ),
                      ]);
                    }
                  }}
                  danger
                  className="ms-2 btn-md"
                  style={{ marginTop: "7px" }}
                >
                  <AiOutlineCloseCircle />
                </Button>
              </div>
            </Col>
          </Row>
          {errors.includes("error-passport_serie") && (
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name={"error-passport_serie"}
                  label={"Comment for passport serie"}
                >
                  <TextArea maxLength={100} showCount />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>

        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          xl={{ span: 6 }}
        >
          <Row gutter={24}>
            <Col span={24} className="d-flex align-items-center">
              <div
                style={{ width: "100%" }}
                className="d-flex align-items-center"
              >
                <Form.Item
                  style={{ width: "100%" }}
                  name={"passport_numbers"}
                  label={t("Passport raqami")}
                >
                  <Input readOnly />
                </Form.Item>

                <Button
                  onClick={() => {
                    if (!errors?.includes("error-passport_numbers")) {
                      setErrors([...errors, "error-passport_numbers"]);
                    } else {
                      setErrors([
                        ...errors?.filter(
                          (item) => item !== "error-passport_numbers"
                        ),
                      ]);
                    }
                  }}
                  danger
                  className="ms-2 btn-md"
                  style={{ marginTop: "7px" }}
                >
                  <AiOutlineCloseCircle />
                </Button>
              </div>
            </Col>
          </Row>
          {errors.includes("error-passport_numbers") && (
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name={"error-passport_numbers"}
                  label={"Comment for passport number"}
                >
                  <TextArea maxLength={100} showCount />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>

        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          xl={{ span: 6 }}
        >
          <Row gutter={24}>
            <Col span={24} className="d-flex align-items-center">
              <div
                style={{ width: "100%" }}
                className="d-flex align-items-center"
              >
                <Form.Item
                  style={{ width: "100%" }}
                  name={"passport_jshr"}
                  label={t("Passport jshr")}
                >
                  <Input readOnly />
                </Form.Item>

                <Button
                  onClick={() => {
                    if (!errors?.includes("error-passport_jshr")) {
                      setErrors([...errors, "error-passport_jshr"]);
                    } else {
                      setErrors([
                        ...errors?.filter(
                          (item) => item !== "error-passport_jshr"
                        ),
                      ]);
                    }
                  }}
                  danger
                  className="ms-2 btn-md"
                  style={{ marginTop: "7px" }}
                >
                  <AiOutlineCloseCircle />
                </Button>
              </div>
            </Col>
          </Row>
          {errors.includes("error-passport_jshr") && (
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name={"error-passport_jshr"}
                  label={"Comment for passport jshr"}
                >
                  <TextArea maxLength={100} showCount />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>

        {/* Diplom infos */}
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          xl={{ span: 6 }}
        >
          <Row gutter={24}>
            <Col span={24} className="d-flex align-items-center">
              <div
                style={{ width: "100%" }}
                className="d-flex align-items-center"
              >
                <Form.Item
                  style={{ width: "100%" }}
                  name={"diploma_serie"}
                  label={t("Diplom/Attestat seriyasi")}
                >
                  <Input readOnly />
                </Form.Item>

                <Button
                  onClick={() => {
                    if (!errors?.includes("error-diploma_serie")) {
                      setErrors([...errors, "error-diploma_serie"]);
                    } else {
                      setErrors([
                        ...errors?.filter(
                          (item) => item !== "error-diploma_serie"
                        ),
                      ]);
                    }
                  }}
                  danger
                  className="ms-2 btn-md"
                  style={{ marginTop: "7px" }}
                >
                  <AiOutlineCloseCircle />
                </Button>
              </div>
            </Col>
          </Row>
          {errors.includes("error-diploma_serie") && (
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name={"error-diploma_serie"}
                  label={"Comment for  diploma serie"}
                >
                  <TextArea maxLength={100} showCount />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>

        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          xl={{ span: 6 }}
        >
          <Row gutter={24}>
            <Col span={24} className="d-flex align-items-center">
              <div
                style={{ width: "100%" }}
                className="d-flex align-items-center"
              >
                <Form.Item
                  style={{ width: "100%" }}
                  name={"diploma_number"}
                  label={t("Diplom/Attestat raqam")}
                >
                  <Input readOnly />
                </Form.Item>

                <Button
                  onClick={() => {
                    if (!errors?.includes("error-diploma_number")) {
                      setErrors([...errors, "error-diploma_number"]);
                    } else {
                      setErrors([
                        ...errors?.filter(
                          (item) => item !== "error-diploma_number"
                        ),
                      ]);
                    }
                  }}
                  danger
                  className="ms-2 btn-md"
                  style={{ marginTop: "7px" }}
                >
                  <AiOutlineCloseCircle />
                </Button>
              </div>
            </Col>
          </Row>
          {errors.includes("error-diploma_number") && (
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name={"error-diploma_number"}
                  label={"Comment for  diploma number"}
                >
                  <TextArea maxLength={100} showCount />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>

        {/* Edu types */}
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          xl={{ span: 6 }}
        >
          <Row gutter={24}>
            <Col span={24} className="d-flex align-items-center">
              <div
                style={{ width: "100%" }}
                className="d-flex align-items-center"
              >
                <Form.Item
                  style={{ width: "100%" }}
                  name={"faculty_name"}
                  label={t("Ta`lim yo`nalishi")}
                >
                  <Input readOnly />
                </Form.Item>

                <Button
                  onClick={() => {
                    if (!errors?.includes("error-faculty_id")) {
                      setErrors([...errors, "error-faculty_id"]);
                    } else {
                      setErrors([
                        ...errors?.filter(
                          (item) => item !== "error-faculty_id"
                        ),
                      ]);
                    }
                  }}
                  danger
                  className="ms-2 btn-md"
                  style={{ marginTop: "7px" }}
                >
                  <AiOutlineCloseCircle />
                </Button>
              </div>
            </Col>
          </Row>
          {errors.includes("error-faculty_id") && (
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name={"error-faculty_id"}
                  label={"Comment for  faculty"}
                >
                  <TextArea maxLength={100} showCount />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>

        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          xl={{ span: 6 }}
        >
          <Row gutter={24}>
            <Col span={24} className="d-flex align-items-center">
              <div
                style={{ width: "100%" }}
                className="d-flex align-items-center"
              >
                <Form.Item
                  style={{ width: "100%" }}
                  name={"education_language_name"}
                  label={t("Ta'lim tili")}
                >
                  <Input readOnly />
                </Form.Item>

                <Button
                  onClick={() => {
                    if (!errors?.includes("error-education_language_id")) {
                      setErrors([...errors, "error-education_language_id"]);
                    } else {
                      setErrors([
                        ...errors?.filter(
                          (item) => item !== "error-education_language_id"
                        ),
                      ]);
                    }
                  }}
                  danger
                  className="ms-2 btn-md"
                  style={{ marginTop: "7px" }}
                >
                  <AiOutlineCloseCircle />
                </Button>
              </div>
            </Col>
          </Row>
          {errors.includes("error-education_language_id") && (
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name={"error-education_language_id"}
                  label={"Comment for  education language"}
                >
                  <TextArea maxLength={100} showCount />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>

        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          xl={{ span: 6 }}
        >
          <Row gutter={24}>
            <Col span={24} className="d-flex align-items-center">
              <div
                style={{ width: "100%" }}
                className="d-flex align-items-center"
              >
                <Form.Item
                  style={{ width: "100%" }}
                  name={"education_type_name"}
                  label={t("Ta'lim shakli")}
                >
                  <Input readOnly />
                </Form.Item>

                <Button
                  onClick={() => {
                    if (!errors?.includes("error-education_type_id")) {
                      setErrors([...errors, "error-education_type_id"]);
                    } else {
                      setErrors([
                        ...errors?.filter(
                          (item) => item !== "error-education_type_id"
                        ),
                      ]);
                    }
                  }}
                  danger
                  className="ms-2 btn-md"
                  style={{ marginTop: "7px" }}
                >
                  <AiOutlineCloseCircle />
                </Button>
              </div>
            </Col>
          </Row>
          {errors.includes("error-education_type_id") && (
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name={"error-education_type_id"}
                  label={"Comment for  education type"}
                >
                  <TextArea maxLength={100} showCount />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>

        {/* Files and photos */}
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          xl={{ span: 6 }}
        >
          <Row gutter={24}>
            <Col span={24} className="d-flex align-items-center">
              <div
                style={{ width: "100%" }}
                className="d-flex align-items-center"
              >
                <Form.Item
                  style={{ width: "100%" }}
                  name={"photo"}
                  label={t("Rasmi")}
                >
                  <Button type="dashed" style={{ width: "100%" }}>
                    <a
                      href={FILE_URL + fileUrls?.image_url}
                      download
                      target="_blank"
                    >
                      <GrView />
                    </a>
                  </Button>
                </Form.Item>

                <Button
                  onClick={() => {
                    if (!errors?.includes("error-photo")) {
                      setErrors([...errors, "error-photo"]);
                    } else {
                      setErrors([
                        ...errors?.filter((item) => item !== "error-photo"),
                      ]);
                    }
                  }}
                  danger
                  className="ms-2 btn-md"
                  style={{ marginTop: "7px" }}
                >
                  <AiOutlineCloseCircle />
                </Button>
              </div>
            </Col>
          </Row>
          {errors.includes("error-photo") && (
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item name={"error-photo"} label={"Comment for photo"}>
                  <TextArea maxLength={100} showCount />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>

        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          xl={{ span: 6 }}
        >
          <Row gutter={24}>
            <Col span={24} className="d-flex align-items-center">
              <div
                style={{ width: "100%" }}
                className="d-flex align-items-center"
              >
                <Form.Item
                  style={{ width: "100%" }}
                  name={"passport_copy"}
                  label={t("Passport nusxasi")}
                >
                  <Button type="dashed" style={{ width: "100%" }}>
                    <a
                      href={FILE_URL + fileUrls?.passport_url}
                      download
                      target="_blank"
                    >
                      <GrView />
                    </a>
                  </Button>
                </Form.Item>

                <Button
                  onClick={() => {
                    if (!errors?.includes("error-passport_copy")) {
                      setErrors([...errors, "error-passport_copy"]);
                    } else {
                      setErrors([
                        ...errors?.filter(
                          (item) => item !== "error-passport_copy"
                        ),
                      ]);
                    }
                  }}
                  danger
                  className="ms-2 btn-md"
                  style={{ marginTop: "7px" }}
                >
                  <AiOutlineCloseCircle />
                </Button>
              </div>
            </Col>
          </Row>
          {errors.includes("error-passport_copy") && (
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name={"error-passport_copy"}
                  label={"Comment for passport copy"}
                >
                  <TextArea maxLength={100} showCount />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>

        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          xl={{ span: 6 }}
        >
          <Row gutter={24}>
            <Col span={24} className="d-flex align-items-center">
              <div
                style={{ width: "100%" }}
                className="d-flex align-items-center"
              >
                <Form.Item
                  style={{ width: "100%" }}
                  name={"diploma_copy"}
                  label={t("Diplom/Attestat va ilova nusxasi")}
                >
                  <Button type="dashed" style={{ width: "100%" }}>
                    <a
                      href={FILE_URL + fileUrls?.diplom_url}
                      download
                      target="_blank"
                    >
                      <GrView />
                    </a>
                  </Button>
                </Form.Item>

                <Button
                  onClick={() => {
                    if (!errors?.includes("error-diploma_copy")) {
                      setErrors([...errors, "error-diploma_copy"]);
                    } else {
                      setErrors([
                        ...errors?.filter(
                          (item) => item !== "error-diploma_copy"
                        ),
                      ]);
                    }
                  }}
                  danger
                  className="ms-2 btn-md"
                  style={{ marginTop: "7px" }}
                >
                  <AiOutlineCloseCircle />
                </Button>
              </div>
            </Col>
          </Row>
          {errors.includes("error-diploma_copy") && (
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name={"error-diploma_copy"}
                  label={"Comment for diplom copy"}
                >
                  <TextArea maxLength={100} showCount />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>

        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          xl={{ span: 6 }}
          className=""
        >
          <Row gutter={24}>
            {fileUrls?.qayta_tayyorlov_sertifikat ? (
              <Col span={24} className="d-flex align-items-center">
                <div
                  style={{ width: "100%" }}
                  className="d-flex align-items-center"
                >
                  <Form.Item
                    style={{ width: "100%" }}
                    name={"diploma_copy"}
                    label={"Qayta tayyorlov sertifikat"}
                  >
                    <Button type="dashed" style={{ width: "100%" }}>
                      <a
                        href={FILE_URL + fileUrls?.qayta_tayyorlov_sertifikat}
                        download
                        target="_blank"
                      >
                        <GrView />
                      </a>
                    </Button>
                  </Form.Item>

                  <Button
                    onClick={() => {
                      if (!errors?.includes("error-diploma_copy")) {
                        setErrors([...errors, "error-diploma_copy"]);
                      } else {
                        setErrors([
                          ...errors?.filter(
                            (item) => item !== "error-diploma_copy"
                          ),
                        ]);
                      }
                    }}
                    danger
                    className="ms-2 btn-md"
                    style={{ marginTop: "7px" }}
                  >
                    <AiOutlineCloseCircle />
                  </Button>
                </div>
              </Col>
            ) : (
              ""
            )}
          </Row>
          {errors.includes("error-diploma_copy") && (
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name={"error-diploma_copy"}
                  label={"Comment for diplom copy"}
                >
                  <TextArea maxLength={100} showCount />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      {/* Submit button */}
      <Row gutter={24}>
        <Col className="text-end" span={24}>
          <Button className="btn-danger" htmlType="submit" type="primary">
            {t("Qaytarish")}
          </Button>
          <Button
            onClick={changeStatus}
            className="mx-3"
            htmlType="button"
            type="primary"
          >
            {t("Qabul qilish")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ApplicantViewer;
