import React from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Switch,
} from "antd";
import { Fragment } from "react";
import { useEffect } from "react";
import instance from "../../../api/axios";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const { Option } = Select;

const EditFaculty = () => {
  // hooks
  const history = useHistory();
  const [form] = Form.useForm();
  const [eduTypes, setEduTypes] = useState([]);
  const [eduLangs, setEduLangs] = useState([]);
  const [admissionTypes, setAdmissionTypes] = useState([]);
  const url = useLocation().pathname;
  let a = url.split("/");
  const [faculty, setFaculty] = useState([]);
  const { t } = useTranslation();

  const onFinish = async (values) => {
    values = {
      ...values,
      status: values?.status ? 1 : 0,
    };
    try {
      const resp = await instance({
        method: "put",
        url: `/faculties/${a[a.length - 1]}`,
        data: values,
      });
      history.push("/faculty");
    } catch (error) {
      message.error("Error in editing faculty");
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: `/faculties/${
            a[a.length - 1]
          }?expand=education_languages, education_types,admission_type`,
        });
        if (resp.status === 200) {
          let a = resp?.data?.faculty?.education_languages?.map(
            (item) => `${item?.id}`
          );
          let b = resp?.data?.faculty?.education_types?.map(
            (item) => `${item?.id}`
          );
          form.setFieldsValue({
            ...resp?.data?.faculty,
            status: parseInt(resp.data?.faculty?.status) === 1,
            edu_langs: a,
            edu_types: b,
            admission_type: resp?.data?.faculty?.admission_type?.id,
          });
        }
      } catch (error) {
        message.error("Fakultet ma'lumotlarini ochishda xatolik!");
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: "/edu-types",
        });
        if (resp?.status === 200) {
          setEduTypes(resp?.data?.edu_types);
        }
      } catch (error) {
        message.error("Error read education types!");
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: "/edu-langs",
        });
        if (resp?.status === 200) {
          setEduLangs(resp?.data?.edu_langs);
        }
      } catch (error) {
        message.error("Error read education langs!");
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: "/admission-types",
        });
        if (resp?.status === 200) {
          setAdmissionTypes(resp?.data?.data);
        }
      } catch (error) {
        message.error("Qabul turi ma'lumotlarini ochishda xatolik!");
      }
    })();
  }, []);

  return (
    <Fragment>
      <Form
        name="basic"
        form={form}
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Row className="add-edu-type" gutter={[16, 16]}>
          <Col span={24}>
            <h3>{t("Ta`lim yo`nalishi o'zgartirish")}</h3>
          </Col>

          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
            xl={{ span: 6 }}
          >
            <Form.Item name="name_uz" label={t("Nomi uz")}>
              <Input placeholder={t("nomi uz")}></Input>
            </Form.Item>
          </Col>

          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
            xl={{ span: 6 }}
          >
            <Form.Item name="name_ru" label={t("Nomi ru")}>
              <Input placeholder="nomi ru"></Input>
            </Form.Item>
          </Col>

          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
            xl={{ span: 6 }}
          >
            <Form.Item name="description" label={t("Tavsif")}>
              <Input placeholder="tavsif"></Input>
            </Form.Item>
          </Col>

          {/* education items */}
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
            xl={{ span: 6 }}
          >
            <Form.Item
              rules={[{ required: true, message: "Please input field value!" }]}
              name={"edu_types"}
              label={t("Ta'lim shakli")}
            >
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder={t("ta'lim turi")}
                allowClear
              >
                {eduTypes?.map((item, i) => (
                  <Select.Option key={i} value={"" + item?.id}>
                    {[
                      i18next.language === "uz" ? item?.name_uz : item?.name_ru,
                    ]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
            xl={{ span: 6 }}
          >
            <Form.Item
              rules={[{ required: true, message: "Please input field value!" }]}
              name={"edu_langs"}
              label={t("Ta'lim tili")}
            >
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="ta'lim tili"
                allowClear
              >
                {eduLangs?.map((item, i) => (
                  <Select.Option key={i} value={"" + item?.id}>
                    {[
                      i18next.language === "uz" ? item?.name_uz : item?.name_ru,
                    ]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
            xl={{ span: 6 }}
          >
            <Form.Item
              rules={[{ required: true, message: "Please input field value!" }]}
              name={"admission_type"}
              label={t("Qabul turi")}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="qabul turini tanlang"
                allowClear
              >
                {admissionTypes?.map((item, i) => (
                  <Select.Option key={i} value={item?.id}>
                    {[
                      i18next.language === "uz" ? item?.name_uz : item?.name_ru,
                    ]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
            xl={{ span: 6 }}
          >
            <Form.Item
              name="status"
              label={t("Holati")}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]} className="my-3">
          <Col span={24} className="text-end">
            <Button htmlType="reset" className="btn-danger" type="primary">
              {t("O'chirish")}
            </Button>
            <Button htmlType="submit" className="mx-3" type="primary">
              {t("Saqlash")}
            </Button>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};

export default EditFaculty;
