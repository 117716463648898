import React from "react";
import { Fragment } from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { FaEye, FaEdit, FaTrashAlt } from "react-icons/fa";
import { Button, Col, message, Row, Switch, Tag } from "antd";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import instance from "../../api/axios";
import { useState } from "react";
import "./admission.scss";
import { useTranslation } from "react-i18next";

const AdmissionType = () => {
  // hooks
  const [admissiontypes, setAdmissiontypes] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: "/admission-types",
        });

        setAdmissiontypes(resp?.data?.data);
      } catch (error) {
        message.error("Qabul ma'lumotlarini ochishda xatolik!");
      }
    })();
  }, [refresh]);
  console.log(admissiontypes);

  return (
    <Fragment>
      <Row>
        <Col className="" span={24}>
          <h3>{t("Qabul turlari")}</h3>
        </Col>
        <Table className="table table-responsive table-bordered table-striped table-hover text-center">
          <Thead className="table-head">
            <Tr>
              <Th>№</Th>
              <Th>{t("Nomi uz")}</Th>
              <Th>{t("Nomi ru")}</Th>
              <Th>{t("Holati")}</Th>
              <Th>{t("Amallar")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {admissiontypes?.map((v, i) => {
              return (
                <Tr>
                  <Td>{i + 1}</Td>
                  <Td>{v?.name_uz}</Td>
                  <Td>{v?.name_ru}</Td>
                  <Td>
                    <Switch checked={v?.status === 1} />
                  </Td>
                  <Td>
                    <NavLink to={"/edit_admissiontype/" + v?.id} type="link">
                      <FaEdit />
                    </NavLink>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Row>
    </Fragment>
  );
};

export default AdmissionType;
