import React from "react";
import { Card, Layout } from "antd";
import { Fragment } from "react";

const { Content } = Layout;
const CustomContent = ({ children }) => {
  return (
    <Fragment>
      <Card className="border-0 w-100 h-100" style={{ backgroundColor: "#F8F8FB" }}>
        {" "}
        {children}
      </Card>
    </Fragment>
  );
};

export default CustomContent;
