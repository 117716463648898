import Applicant from "../pages/applicant/ApplyDocs";
import Checker from "../pages/checker/Checker";
import { HiDocumentText, HiOutlineDocumentDownload } from "react-icons/hi";
import Login from "../pages/login/Login";
import ApplicantViewer from "../pages/checker/applicant viewer/ApplicantViewer";
import MainPage from "../pages/main";
import {
  FaHome,
  FaReadme,
  FaLanguage,
  FaUserEdit,
  FaPollH,
} from "react-icons/fa";
import { SiReadthedocs } from "react-icons/si";
import { GoGitBranch } from "react-icons/go";
import { BiError } from "react-icons/bi";
import ApplicantUi from "../pages/applicant/ApplicantUi";
import EducationTypes from "../pages/education types/EducationTypes";
import EducationLanguage from "../pages/education languages/EducationLanguage";
import CreateEduTypes from "../pages/education types/crud/CreateEduTypes";
import EditEduTypes from "../pages/education types/crud/EditEduTypes";
import CreateEduLangs from "../pages/education languages/crud/CreateEduLangs";
import EditEduLangs from "../pages/education languages/crud/EditEduLangs";
import Faculties from "../pages/faculty/Faculties";
import CreateFaculty from "../pages/faculty/crud/CreateFaculty";
import EditFaculty from "../pages/faculty/crud/EditFaculty";
import Registration from "../pages/login/Registration/Registration";
import ResetPassword from "../pages/login/Registration/ResetPassword";
import LoginSetting from "../pages/login setting/LoginSetting";
import AdmissionType from "../pages/admission type/AdmissionType";
import EditAdmissionType from "../pages/admission type/crud/EditAdmissionType";
import EducationDirections from "../pages/education directions/EducationDirections";
import CreateEduDirection from "../pages/education directions/crud/CreateEduDirection";
import EditDirection from "../pages/education directions/crud/EditEduDirection";
import Requirements from "../pages/reports/Requirements";
import UsingModel from "../pages/how to use/UsingModel";

export const routes = [
  {
    name: "Asosiy",
    path: "/",
    component: MainPage,
    icon: <FaHome />,
    config: {
      showLink: true,
      exact: true,
      layout: true,
      key: ["super-admin", "receiver", "receiver_malaka_oshirish", "applier"],
    },
  },
  {
    name: "Hujjat topshiruvchilar",
    path: "/checker",
    component: Checker,
    icon: <HiDocumentText />,
    config: {
      showLink: true,
      exact: true,
      layout: true,
      key: ["super-admin", "receiver", "receiver_malaka_oshirish"],
    },
  },
  {
    name: "Ta'lim tili",
    path: "/education_language",
    component: EducationLanguage,
    icon: <FaLanguage />,
    config: {
      showLink: true,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Create edu langs",
    path: "/create_edu_langs",
    component: CreateEduLangs,
    icon: <HiOutlineDocumentDownload />,
    config: {
      showLink: false,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Edit edu langs",
    path: "/edit_edu_langs/:id",
    component: EditEduLangs,
    icon: <HiOutlineDocumentDownload />,
    config: {
      showLink: false,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Ta'lim shakli",
    path: "/education_types",
    component: EducationTypes,
    icon: <FaReadme />,
    config: {
      showLink: true,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Create edu types",
    path: "/create_edu_types",
    component: CreateEduTypes,
    icon: <HiOutlineDocumentDownload />,
    config: {
      showLink: false,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Edit edu types",
    path: "/edit_edu_types/:id",
    component: EditEduTypes,
    icon: <HiOutlineDocumentDownload />,
    config: {
      showLink: false,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Yo'nalishlar",
    path: "/education_direction",
    component: EducationDirections,
    icon: <GoGitBranch />,
    config: {
      showLink: false,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Create edu direction",
    path: "/create_edu_direction",
    component: CreateEduDirection,
    icon: <HiOutlineDocumentDownload />,
    config: {
      showLink: false,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Edit edu direction",
    path: "/edit_edu_direction/:id",
    component: EditDirection,
    icon: <HiOutlineDocumentDownload />,
    config: {
      showLink: false,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Ta`lim yo`nalishlari",
    path: "/faculty",
    component: Faculties,
    icon: <SiReadthedocs />,
    config: {
      showLink: true,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Qabul turi",
    path: "/admission_type",
    component: AdmissionType,
    icon: <FaPollH />,
    config: {
      showLink: true,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Topshirish qoidalari",
    path: "/applying_requirements",
    component: Requirements,
    icon: <BiError />,
    config: {
      showLink: true,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Edit admission type",
    path: "/edit_admissiontype/:id",
    component: EditAdmissionType,
    icon: <FaPollH />,
    config: {
      showLink: false,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Create faculty",
    path: "/create_faculty",
    component: CreateFaculty,
    icon: <HiOutlineDocumentDownload />,
    config: {
      showLink: false,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Edit faculty",
    path: "/edit_faculty/:id",
    component: EditFaculty,
    icon: <HiOutlineDocumentDownload />,
    config: {
      showLink: false,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "ApplicantViewer",
    path: "/applicant_viewer/:id",
    component: ApplicantViewer,
    icon: null,
    config: {
      showLink: false,
      exact: true,
      layout: true,
      key: ["super-admin", "receiver", "receiver_malaka_oshirish"],
    },
  },
  {
    name: "Hujjat topshirish",
    path: "/applicant",
    component: Applicant,
    icon: <HiOutlineDocumentDownload />,
    config: {
      showLink: true,
      exact: true,
      layout: true,
      key: ["applier"],
    },
  },
  {
    name: "ApplicantUi",
    path: "/applicantui",
    component: ApplicantUi,
    icon: <HiOutlineDocumentDownload />,
    config: {
      showLink: false,
      exact: true,
      layout: true,
      key: ["super-admin", "applier"],
    },
  },
  {
    name: "Profil sozlamalari",
    path: "/edit_profil",
    component: LoginSetting,
    icon: <FaUserEdit />,
    config: {
      showLink: true,
      exact: true,
      layout: true,
      key: ["applier"],
    },
  },
  // {
  //   name: "Yo'riqnoma",
  //   path: "/how_to_use",
  //   component: UsingModel,
  //   icon: <FaUserEdit />,
  //   config: {
  //     showLink: true,
  //     exact: true,
  //     layout: true,
  //     key: ["super-admin", "receiver", "receiver_malaka_oshirish"],
  //   },
  // },
];

export const public_routes = [
  {
    name: "Registration",
    path: "/registration",
    component: Registration,
    icon: <HiOutlineDocumentDownload />,
    config: {
      showLink: false,
      exact: true,
      layout: false,
      key: ["applier"],
    },
  },
  {
    name: "Reset password",
    path: "/reset_password",
    component: ResetPassword,
    icon: <HiOutlineDocumentDownload />,
    config: {
      showLink: false,
      exact: true,
      layout: false,
      key: ["applier"],
    },
  },
  {
    name: "Login",
    path: "/login",
    component: Login,
    icon: <HiOutlineDocumentDownload />,
    config: {
      showLink: false,
      exact: true,
      layout: false,
      key: [""],
    },
  },
];
