import React from "react";
import { ExportToExcel } from "./excel_generate";
import instance from "../api/axios";
import axios from "axios";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Button } from "antd";
import { FaPrint } from "react-icons/fa";

const LoadExcelData = ({ from, to, addmission_id, status }) => {
  const fileName = "myfile";
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  // here enter filename for your excel file

  const getFile = async () => {
    const resp = await axios({
      method: "get",
      url: `https://apiadmission.ftti.uz/public/api/excel/reports?from=${from}&to=${to}&admission_type_id=${addmission_id}&status=${status}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    });
    const ws = XLSX.utils.json_to_sheet(resp?.data?.applications);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      disabled={!from && !to}
      onClick={getFile}
      className=""
      type={"primary"}
    >
      <FaPrint className="mx-1" /> Excel
    </Button>
  );
};

export default LoadExcelData;
