import { Fragment, React } from "react";
import { useRef, useState, useEffect } from "react";
import "./login.scss";
import { Form, Input, Button, Spin, message, Select } from "antd";
import loginImg from "../../assets/images/login/login_img.svg";
import { NavLink, useHistory } from "react-router-dom";
import instance from "../../api/axios";
import { useDispatch } from "react-redux";
import { SAVE_USER } from "../../redux/user/actionType";
import { FiKey } from "react-icons/fi";
import i18n from "../../i18next";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";

const LOGIN_URL = "/auth/login";
const { Option } = Select;

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const [lang, setLang] = useState(localStorage.getItem("i18lang"));
  const { t } = useTranslation();

  useEffect(() => {
    userRef.current.focus();
  }, []);
  useEffect(() => {
    setErrMsg("");
  }, [user, password]);

  const onFinish = async (values) => {
    console.log("Success:", values);
    try {
      setLoader(true);
      const data = new FormData();
      data.append("username", values?.username);
      data.append("password", values?.password);
      const response = await instance({
        url: LOGIN_URL,
        method: "post",
        data: data,
        headers: {
          Accept: "application/json",
        },
      });
      if (response?.data?.status === "Success") {
        localStorage.setItem("access_token", response?.data?.data?.token);
        instance.defaults.headers[`Authorization`] =
          "Bearer " + response?.data?.data?.token;
        form.resetFields();
        const me = await instance({
          url: "/me",
        });
        if (me?.status === 200) {
          dispatch({
            type: SAVE_USER,
            payload: me?.data,
            isAuth: true,
            role: me?.data?.role,
          });
          history.push("/");
        }
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg("Serverda uzilish!");
      } else if (err?.response?.status == 400) {
        setErrMsg("Foydalanuvchi nomi yoki parol xato!");
      } else if (err?.response?.status == 401) {
        message.error("Bunday foydalanuvchi nomi va parol mavud emas!");
      } else {
        setErrMsg("Tizimga kirishda xatolik!");
      }
      setLoader(false);

      errRef.current.focus();
    }
  };

  const langs = [
    {
      key: "uz",
      label: "O'zbekcha",
    },
    {
      key: "ru",
      label: "Pусский",
    },
  ];

  const onChange = (key) => {
    localStorage.setItem("i18lang", key);
    i18n.changeLanguage(key);
    setLang(key);
  };

  return (
    <Fragment>
      <Spin size="large" spinning={loader}>
        <div
          className="login-page"
          style={{ position: "relative", overflow: "hidden" }}
        >
          <div className="login-box">
            <Form
              form={form}
              name="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              layout="vertical"
            >            
              <h3 className="form-title my-3">ONLINE QABUL FTTI</h3>
              <Form.Item
                label="Login"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Iltimos login kiriting!",
                  },
                ]}
              >
                <Input
                  type="text"
                  ref={userRef}
                  placeholder="login"
                  className="login-form-username"
                />
              </Form.Item>

              <Form.Item
                label="Parol"
                name="password"
                rules={[
                  { required: true, message: "Iltimos parolni kiriting!" },
                ]}
              >
                <Input.Password
                  autoComplete="new-password"
                  placeholder="parol"
                  className="login-form-password"
                />
              </Form.Item>

              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Kirish
              </Button>

              <div className="d-flex align-items-center justify-content-between mt-3 registr-link">
                <NavLink to={"/registration"}>Ro'yhatdan o'tish</NavLink>
                <NavLink to={"/reset_password"}>
                  <FiKey /> Parolni tiklash
                </NavLink>
              </div>
            </Form>
            <div className="illustration-wrapper">
              <img src={loginImg} alt="Login" />
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};

export default Login;
