import { Badge, Card, Col, Divider, message, Row, Tag } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import ApplicantUi from "../applicant/ApplicantUi";
import { useTranslation } from "react-i18next";
import {
  FaFileDownload,
  FaUserCheck,
  FaUserAltSlash,
  FaUserEdit,
  FaUserPlus,
} from "react-icons/fa";
import "../main/main.scss";
import { useEffect, Fragment, useState } from "react";
import instance from "../../api/axios";
import { NavLink } from "react-router-dom";
import i18next from "i18next";

const colors = ["gold", "orange", "green", "blue"];

const Reports = () => {
  const { t } = useTranslation();
  const colors = ["#6C757D", "orange", "green", "blue"];
  const [statisticAll,setStatisticAll]=useState()
  // hooks
  const [statistics, serStatistics] = useState();

  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: "application/reports",
        });
        setStatisticAll(resp?.data);
      } catch (error) {
        message.error("Serverda uzilish yuz bermoqda!");
      }
    })();
  }, []);

  useEffect(()=>{
      if(statisticAll){
        serStatistics(statisticAll[i18next.language])
      }
  },[statisticAll,i18next.language])
  
  const getTotals = (obj) => {
    let a = 0;
    if (obj) {
      Object.entries(obj)?.map(([key, value]) => {
        a += value;
      });
    }
    return a;
  };
  return (
    <div className="statistics">
      <Row gutter={24}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 10 }}
          lg={{ span: 10 }}
          className="statics-wrapper d-flex"
        >
          <Card
            className="card-statics"
            title={
              <NavLink to={"/checker"}>
                <div className="statics-title">
                  <h2>
                    {t("Jami arizalar")}{" "}
                    <span>{getTotals(statistics?.total)}</span>
                  </h2>
                  <FaFileDownload className="icon-main" />
                </div>
              </NavLink>
            }
            style={{ width: "100%" }}
          >
            <div className="statics-number">
              <ul>
                {statistics?.total &&
                  Object.entries(statistics?.total).map(([key, value], i) => (
                    <li>
                      <Badge color={colors[i]} text={key} />{" "}
                      <span
                        style={{ color: "white", backgroundColor: colors[i] }}
                        className="badge rounded-pill"
                      >
                        {value}
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
          </Card>
        </Col>

        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 14 }}
          lg={{ span: 14 }}
        >
          <Row gutter={24}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              className="statics-wrapper"
            >
              <Card
                className="card-statics"
                title={
                  <NavLink to={"/checker"}>
                    <div className="statics-title">
                      <h4>
                        {t("Qaytarilgan")}{" "}
                        <span>{getTotals(statistics?.rejected)}</span>
                      </h4>
                      <FaUserAltSlash className="icon" />
                    </div>
                  </NavLink>
                }
                style={{ width: "100%" }}
              >
                <div className="statics-number">
                  <ul>
                    {statistics?.rejected &&
                      Object.entries(statistics?.rejected).map(
                        ([key, value], i) => (
                          <li>
                            <Badge color={colors[i]} text={key} />{" "}
                            <span
                              style={{
                                color: "white",
                                backgroundColor: colors[i],
                              }}
                              className="badge rounded-pill"
                            >
                              {value}
                            </span>
                          </li>
                        )
                      )}
                  </ul>
                </div>
              </Card>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              className="statics-wrapper"
            >
              <Card
                className="card-statics"
                title={
                  <NavLink to={"/checker"}>
                    <div className="statics-title">
                      <h4>
                        {t("O'zgartirilgan")}{" "}
                        <span>{getTotals(statistics?.edited)}</span>
                      </h4>
                      <FaUserEdit className="icon" />
                    </div>
                  </NavLink>
                }
                style={{ width: "100%" }}
              >
                <div className="statics-number">
                  <ul>
                    {statistics?.edited &&
                      Object.entries(statistics?.edited).map(
                        ([key, value], i) => (
                          <li>
                            <Badge color={colors[i]} text={key} />{" "}
                            <span
                              style={{
                                color: "white",
                                backgroundColor: colors[i],
                              }}
                              className="badge rounded-pill"
                            >
                              {value}
                            </span>
                          </li>
                        )
                      )}
                  </ul>
                </div>
              </Card>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              className="statics-wrapper"
            >
              <Card
                className="card-statics"
                title={
                  <NavLink to={"/checker"}>
                    <div className="statics-title">
                      <h4>
                        {t("Qabul qilingan")}{" "}
                        <span>{getTotals(statistics?.accepted)}</span>
                      </h4>
                      <FaUserCheck className="icon" />
                    </div>
                  </NavLink>
                }
                style={{ width: "100%" }}
              >
                <div className="statics-number">
                  <ul>
                    {statistics?.accepted &&
                      Object.entries(statistics?.accepted).map(
                        ([key, value], i) => (
                          <li>
                            <Badge color={colors[i]} text={key} />{" "}
                            <span
                              style={{
                                color: "white",
                                backgroundColor: colors[i],
                              }}
                              className="badge rounded-pill"
                            >
                              {value}
                            </span>
                          </li>
                        )
                      )}
                  </ul>
                </div>
              </Card>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              className="statics-wrapper"
            >
              <Card
                className="card-statics"
                title={
                  <NavLink to={"/checker"}>
                    <div className="statics-title">
                      <h4>
                        {t("Yangi topshirilgan")}{" "}
                        <span>{getTotals(statistics?.new)}</span>
                      </h4>
                      <FaUserPlus className="icon" />
                    </div>
                  </NavLink>
                }
                style={{ width: "100%" }}
              >
                <div className="statics-number">
                  <ul>
                    {statistics?.new &&
                      Object.entries(statistics?.new).map(([key, value], i) => (
                        <li>
                          <Badge color={colors[i]} text={key} />{" "}
                          <span
                            style={{
                              color: "white",
                              backgroundColor: colors[i],
                            }}
                            className="badge rounded-pill"
                          >
                            {value}
                          </span>
                        </li>
                      ))}
                  </ul>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Reports;
