import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Spin,
  Switch,
} from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import instance from "../../../api/axios";
import i18next, { i18n } from "i18next";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const EditDirection = () => {
  // hooks
  const history = useHistory();
  const path = useLocation()?.pathname;
  let a = path.split("/");
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [faculties, setFaculties] = useState([]);
  const {t} = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: "/faculties",
        });

        setFaculties(resp?.data?.faculties);
      } catch (e) {
        message.error(e?.response?.data?.message);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: `/faculties-directions/${a[a?.length - 1]}`,
        });
        const { name_uz, name_ru, description, status, faculty_id } =
          resp?.data?.data;
        form.setFieldsValue({
          name_uz,
          name_ru,
          description,
          status: status === 1,
          faculty_id,
        });
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    })();
  }, []);

  // onFinish
  const onFinish = async (values) => {
    let data = new FormData();
    values = { ...values, status: values?.status ? 1 : 0 };
    try {
      setLoader(true);
      const resp = await instance({
        method: "put",
        url: `/faculties-directions/${a[a?.length - 1]}`,
        data: values,
      });
      history.push("/education_direction");
    } catch (error) {
      message.error("Yo'nalishni saqlashda xatolik!");
      setLoader(false);
    }
  };

  return (
    <Fragment>
      <Spin spinning={loader}>
        <Form
          name="basic"
          form={form}
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Row className="add-edu-lang" gutter={[16, 16]}>
            <Divider orientation="center">{t("O'zgartirish")}</Divider>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Iltimos maydonlarni to`ldiring",
                  },
                ]}
                name="name_uz"
                label={t("Nomi uz")}
              >
                <Input placeholder="o'zbekcha nomi"></Input>
              </Form.Item>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Iltimos maydonlarni to`ldiring",
                  },
                ]}
                name="name_ru"
                label={t("Nomi ru")}
              >
                <Input placeholder="ruscha nomi"></Input>
              </Form.Item>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Iltimos maydonlarni to`ldiring",
                  },
                ]}
                name="description"
                label={t("Tavsif")}
              >
                <Input placeholder="tavsif"></Input>
              </Form.Item>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item name="status" label={t("Holati")} valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item
                rules={[
                  { required: true, message: "Iltimos maydonlarni to'ldiring" },
                ]}
                name={"faculty_id"}
                label={t("Fakultet")}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Fakultet"
                  // onChange={handleChange}
                  allowClear
                >
                  {faculties?.map((item, i) => (
                    <Select.Option value={item?.id} key={i}>
                      {i18next.language === "uz"
                        ? item?.name_uz
                        : item?.name_ru}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24} className="text-end">
              <Button htmlType="reset" className="btn-danger" type="primary">
                {t("O'chirish")}
              </Button>
              <Button htmlType="submit" className="mx-3" type="primary">
                {t("Saqlash")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Fragment>
  );
};

export default EditDirection;
