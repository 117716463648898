import { Button, Col, Divider, Empty, message, Row, Tag } from "antd";
import i18next from "i18next";
import React from "react";
import { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import instance from "../../api/axios";
import { FILE_URL } from "../../api/constants";
import { BiError } from "react-icons/bi";
import errorImg from "../../assets/images/undraw_no_data_re_kwbl (2).svg";

import "./applicant.scss";
import { useTranslation } from "react-i18next";
import { GrCertificate } from "react-icons/gr";

const ApplicantUi = () => {
  // useStates
  const { t } = useTranslation();
  const [applicantInfos, setApplicantInfos] = useState();
  const [applicantFeedback, setApplicantFeedback] = useState([]);
  const [requirements, setRequirements] = useState();
  const [loading, setLoading] = useState(false);

  const [fileUrls, setFileUrls] = useState({
    img: [],
    passport: [],
    diplom: [],
    sertificate: [],
  });

  const showApplicantStatus = (userStatus) => {
    switch (userStatus) {
      case 0:
        return <span className="text-info">{t("yuborildi")}</span>;
      case 1:
        return <span className="text-danger">{t("qaytarildi")}</span>;
      case 2:
        return <span className="text-warning">{t("o`zgartirlidi")}</span>;
      case 3:
        return <span className="text-success">{t("Qabul qilindi")}</span>;
      case -3:
        return (
          <span className="text-success">
            {t("Qabul qilindi (ruxsatnoma berilishini kuting!)")}
          </span>
        );
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: "/user/application?expand=region,district,faculty,education_language,education_type,admission_type",
        });
        let data = resp?.data?.data?.application;

        setApplicantInfos(resp?.data?.data?.application);
        setApplicantFeedback(resp?.data?.data?.feedbacks);
        setFileUrls({
          img: [
            {
              uid: "1",
              name:
                "image" +
                data?.photo?.substring(
                  data?.photo?.lastIndexOf(".") + 1,
                  data?.photo?.length
                ),
              ststus: "success",
              url: FILE_URL + data?.folder + "/" + data?.photo,
            },
          ],
          passport: [
            {
              uid: "1",
              name:
                "image" +
                data?.passport_copy?.substring(
                  data?.passport_copy?.lastIndexOf(".") + 1,
                  data?.data?.passport_copy?.length
                ),
              ststus: "success",
              url: FILE_URL + data?.folder + "/" + data?.passport_copy,
            },
          ],
          diplom: [
            {
              uid: "1",
              name:
                "image" +
                data?.diploma_copy?.substring(
                  data?.diploma_copy?.lastIndexOf(".") + 1,
                  data?.diploma_copy?.length
                ),
              ststus: "success",
              url: FILE_URL + data?.folder + "/" + data?.diploma_copy,
            },
          ],
        });
      } catch (e) {
        // message.error("Error get your application");
      }
    })();
  }, []);

  // get applying requirements
  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: "/admission-requirements",
        });
        if (resp?.status === 200) {
          setRequirements(resp?.data?.data);
        }
      } catch (error) {
        message.error("Ma'lumotlarni ochishda xatolik!");
      }
    })();
  }, []);

  const getPermission = async () => {
    try {
      setLoading(true); // Set loading to true before making the request

      const resp = await instance({
        url: `http://apiadmission.ftti.uz/api/applications-pdf/${applicantInfos?.id}`,
        method: "get",
      });

      let a = document.createElement("a");
      a.href = `data:application/pdf;base64, ${resp?.data}`;
      a.target = "_blank";

      let fileName = `file.pdf`;
      console.log(fileName);
      a.download = fileName;
      a.click();
      a.remove();
    } catch (error) {
      message.error(error);
    } finally {
      setLoading(false); // Set loading back to false after the request completes
    }
  };

  return (
    <Fragment>
      {applicantInfos ? (
        <div className="applicant-ui-wrapper">
          <h3 className="text-center fw-bold">{t("Mening ma`lumotlarim")}</h3>

          <Row gutter={[16, 8]}>
            <Col
              className="gutter-row personel-infos"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <div className="image-wrapper">
                <img src={fileUrls?.img[0]?.url} alt="" />
              </div>

              <h3 className="text-center">{t("Shaxsiy malumotlar")}</h3>

              <div className="personel-text">
                <p>
                  {t("FIO")}: <span>{applicantInfos?.name}</span>
                  <span>{applicantInfos?.surname}</span>
                  <span>{applicantInfos?.middle_name}</span>
                </p>
                <p>
                  {t("Tug'ulgan yili")}:{" "}
                  <span>{applicantInfos?.birthdate}</span>
                </p>
                <p>
                  {t("Jinsi")}:{" "}
                  <span>
                    {applicantInfos?.gender === 0
                      ? `${t("Ayol")}`
                      : `${t("Erkak")}`}
                  </span>
                </p>
                <p>
                  {t("Viloyat")}: <span>{applicantInfos?.region?.name}</span>
                </p>
                <p>
                  {t("Tuman")}: <span>{applicantInfos?.district?.name}</span>
                </p>
                <p>
                  {t("Manzil")}: <span>{applicantInfos?.address}</span>
                </p>
                <p>
                  {t("Telefon 1")}: <span>{applicantInfos?.telephone}</span>
                </p>
                <p>
                  {t("Telefon 2")}: <span>{applicantInfos?.telephone2}</span>
                </p>
                <p>
                  {t("Ta'lim shakli")}:{" "}
                  <span>
                    {
                      applicantInfos?.admission_type[
                        i18next?.language === "uz" ? "name_uz" : "name_ru"
                      ]
                    }
                  </span>
                </p>
              </div>

              <h3 className="text-center mt-3">{t("Hujjat holati")}</h3>
              <div className="personel-text">
                <p>
                  {t("Sizning ma'lumotlaringiz")}:
                  <span>{showApplicantStatus(applicantInfos?.status)}</span>
                </p>

                {applicantInfos?.status === 3 ? (
                  <>
                    {/* <Button
                      loading={loading}
                      className="text-dark"
                      onClick={getPermission}
                      type="dashed"
                    >
                      <GrCertificate />
                      &nbsp;
                      {t("Ruxsatnomani yuklab olish")}
                    </Button> */}
                    <a
                      // rel="noreferrer"
                      className="text-dark"
                      href={`https://apiadmission.ftti.uz/api/applications-pdf/${applicantInfos?.id}`}
                      // target="_blank"
                    >
                      <GrCertificate />
                      &nbsp; {t("Ruxsatnomani yuklab olish")}
                    </a>
                  </>
                ) : (
                  ""
                )}
                {applicantInfos?.status === -3 ? "" : ""}
              </div>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 16 }}
            >
              <Col className="gutter-row passport-infos" span={24}>
                <h3 className="text-center">{t("Passport malumotlar")}</h3>
                <div className="passport-text">
                  <p>
                    {t("Passport seriyasi")}:
                    <span>{applicantInfos?.passport_serie}</span>
                  </p>
                  <p>
                    {t("Passport raqami")}:
                    <span>{applicantInfos?.passport_numbers}</span>
                  </p>
                  <p>
                    {t("Passport JSHIR")}:
                    <span>{applicantInfos?.passport_jshr}</span>
                  </p>
                  <p>
                    {t("Passport rangli nusxasi")}:{" "}
                    <a
                      href={
                        fileUrls?.passport?.length && fileUrls?.passport[0]?.url
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("Yuklab olish")}
                    </a>
                  </p>
                </div>
              </Col>

              <Col className="gutter-row diplom-infos" span={24}>
                <h3 className="text-center">{t("Diplom malumotlar")}</h3>

                <div className="diplom-text">
                  <p>
                    {t("Diplom seriyasi")}:{" "}
                    <span>{applicantInfos?.diploma_serie}</span>
                  </p>
                  <p>
                    {t("Diplom raqami")}:{" "}
                    <span>{applicantInfos?.diploma_number}</span>
                  </p>
                  <p>
                    {t("Diplom berilgan sana")}:{" "}
                    <span>{applicantInfos?.diploma_given}</span>
                  </p>
                  <p>
                    {t("Diplom nusxasi")}:{" "}
                    <a
                      href={
                        fileUrls?.diplom?.length && fileUrls?.diplom[0]?.url
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      {t("Yuklab olish")}
                    </a>
                  </p>
                  {applicantInfos?.qayta_tayyorlov_sertifikat ? (
                    <p>
                      {t("Sertifikat")}:{" "}
                      <a
                        href={
                          fileUrls?.sertificate?.length &&
                          fileUrls?.sertificate[0]?.url
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue"
                      >
                        {t("Yuklab olish")}
                      </a>
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </Col>

              <Col className="gutter-row education-infos" span={24}>
                <h3 className="text-center">{t("Ta'lim malumotlar")}</h3>

                <div className="education-text">
                  <p>
                    {t("Ta`lim yo`nalishi")}:
                    <span>
                      {applicantInfos.faculty &&
                        applicantInfos.faculty[
                          i18next?.language === "uz" ? "name_uz" : "name_ru"
                        ]}
                    </span>
                  </p>
                  <p>
                    {t("Ta'lim tili")}:
                    <span>
                      {applicantInfos?.education_language &&
                        applicantInfos?.education_language[
                          i18next?.language === "uz" ? "name_uz" : "name_ru"
                        ]}
                    </span>
                  </p>
                  <p>
                    {t("Ta'lim shakli")}:
                    <span>
                      {applicantInfos?.education_type &&
                        applicantInfos?.education_type[
                          i18next?.language === "uz" ? "name_uz" : "name_ru"
                        ]}
                    </span>
                  </p>
                </div>
              </Col>
            </Col>
          </Row>
        </div>
      ) : (
        <Fragment>
          <div className="no-data-applicantui">
            <h5 className="">
              {" "}
              {requirements ? requirements[`title_${i18next.language}`] : ""}
              &nbsp;
              <BiError className="fs-3 text-danger" />
            </h5>
            <div
              dangerouslySetInnerHTML={{
                __html: requirements
                  ? requirements[`content_${i18next.language}`]
                  : "",
              }}
            ></div>
            <img src={errorImg} alt="network error!" />
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ApplicantUi;
