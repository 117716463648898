import React from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Spin,
  Switch,
} from "antd";
import { Fragment } from "react";
import { useEffect } from "react";
import instance from "../../../api/axios";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const EditAdmissionType = () => {
  // hooks
  const history = useHistory();
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const url = useLocation().pathname;
  let a = url.split("/");
  const {t} = useTranslation();

  const onFinish = async (values) => {
    values = {
      ...values,
      status: values?.status ? 1 : 0,
    };
    try {
      setLoader(true);
      const resp = await instance({
        method: "post",
        url: `/admission-types/update/${a[a.length - 1]}`,
        data: values,
      });
      history.push("/admission_type");
    } catch (error) {
      message.error("Qabul turini o'zgartirishda xatolik!");
      setLoader(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: `/admission-types/show/${a[a.length - 1]}`,
        });
        if (resp.status === 200) {
          form.setFieldsValue({
            ...resp?.data?.data,
            status: parseInt(resp.data?.data?.status) === 1,
          });
        }
      } catch (error) {
        message.error("Qabul ma'lumotlarini ochishda xatolik!");
      }
    })();
  }, []);

  return (
    <Fragment>
      <Spin spinning={loader}>
        <div className="edit-admission-type">
          <Form
            name="basic"
            form={form}
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <h3>{t("Qabul turini o'zgartirish")}</h3>

            <Row className="add-edu-type" gutter={[16, 16]}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <Form.Item name="name_uz" label={t("Nomi uz")}>
                  <Input placeholder="name uz"></Input>
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <Form.Item name="name_ru" label={t("Nomi uz")}>
                  <Input placeholder="name ru"></Input>
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <Form.Item name="status" label={t("Holati")} valuePropName="checked">
                  <Switch />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]} className="my-3">
              <Col span={24} className="text-end">
                <Button htmlType="reset" className="btn-danger" type="primary">
                  {t("O'chirish")}
                </Button>
                <Button htmlType="submit" className="mx-3" type="primary">
                  {t("Saqlash")}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Spin>
    </Fragment>
  );
};

export default EditAdmissionType;
