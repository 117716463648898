import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Spin,
  Switch,
} from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import instance from "../../../api/axios";
import "../faculties.scss";
import i18next from "i18next";

const { Option } = Select;

const CreateFaculty = () => {
  const history = useHistory();
  // hooks
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [eduTypes, setEduTypes] = useState([]);
  const [eduLangs, setEduLangs] = useState([]);
  const [admissionTypes, setAdmissionTypes] = useState([]);
  const { t } = useTranslation();

  const onFinish = async (values) => {
    let data = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      if (key == "status") {
        data.append(key, value ? 1 : 0);
      } else if (["edu_types", "edu_langs"].includes(key)) {
        data?.append(key, JSON.stringify(value));
      } else {
        data.append(key, value);
      }
    });
    try {
      setLoader(true);
      const resp = await instance({
        method: "post",
        url: "/faculties",
        data: data,
      });
      history.push("/faculty");
    } catch (error) {
      message.error("error in create faculty");
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: "/edu-types",
        });
        if (resp?.status === 200) {
          setEduTypes(resp?.data?.edu_types);
        }
      } catch (error) {
        message.error("Ta'lim turi ma'lumotlarini ochishda xatolik!");
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: "/edu-langs",
        });
        if (resp?.status === 200) {
          setEduLangs(resp?.data?.edu_langs);
        }
      } catch (error) {
        message.error("Ta'lim tili ma'lumotlarini ochishda xatolik!");
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: "/admission-types",
        });
        if (resp?.status === 200) {
          setAdmissionTypes(resp?.data?.data);
        }
      } catch (error) {
        message.error("Qabul turi ma'lumotlarini ochishda xatolik!");
      }
    })();
  }, []);

  return (
    <Fragment>
      <Spin spinning={loader}>
        <Form
          name="basic"
          form={form}
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Row className="add-edu-type" gutter={[16, 16]}>
            <Col span={24}>
              <h3>{t("Ta`lim yo`nalishi qo'shish")}</h3>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item
                rules={[
                  { required: true, message: "Iltimos maydonlarni to'ldiring" },
                ]}
                name="name_uz"
                label={t("Nomi uz")}
              >
                <Input placeholder={t("o'zbekcha nomi")}></Input>
              </Form.Item>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item
                rules={[
                  { required: true, message: "Iltimos maydonlarni to'ldiring" },
                ]}
                name="name_ru"
                label={t("Nomi ru")}
              >
                <Input placeholder={t("ruscha nomi")}></Input>
              </Form.Item>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item
                rules={[
                  { required: true, message: "Iltimos maydonlarni to'ldiring" },
                ]}
                name="description"
                label={t("Tavsif")}
              >
                <Input placeholder={t("tavsif")}></Input>
              </Form.Item>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item
                rules={[
                  { required: true, message: "Iltimos maydonlarni to'ldiring" },
                ]}
                name="status"
                label={t("Holati")}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            {/* education items */}
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item
                rules={[
                  { required: true, message: "Iltimos maydonlarni to'ldiring" },
                ]}
                name={"edu_types"}
                label={t("Ta'lim shakli")}
              >
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder={t("ta'lim shakli")}
                  // onChange={handleChange}
                  allowClear
                >
                  {eduTypes?.map((item, i) => (
                    <Select.Option key={i} value={"" + item?.id}>
                      {[
                        i18next.language === "uz"
                          ? item?.name_uz
                          : item?.name_ru,
                      ]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item
                rules={[
                  { required: true, message: "Iltimos maydonlarni to'ldiring" },
                ]}
                name={"edu_langs"}
                label={t("Ta'lim tili")}
              >
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder={t("ta'lim tili")}
                  allowClear
                >
                  {eduLangs?.map((item, i) => (
                    <Select.Option key={i} value={"" + item?.id}>
                      {[
                        i18next.language === "uz"
                          ? item?.name_uz
                          : item?.name_ru,
                      ]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item
                rules={[
                  { required: true, message: "Please input field value!" },
                ]}
                name={"admission_type_id"}
                label={t("Qabul turi")}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder={t("qabul turini tanlang")}
                  allowClear
                >
                  {admissionTypes?.map((item, i) => (
                    <Select.Option key={i} value={"" + item?.id}>
                      {[
                        i18next.language === "uz"
                          ? item?.name_uz
                          : item?.name_ru,
                      ]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24} className="text-end">
              <Button htmlType="reset" className="btn-danger" type="primary">
                {t("O'chirish")}
              </Button>
              <Button htmlType="submit" className="mx-3" type="primary">
                {t("Saqlash")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Fragment>
  );
};

export default CreateFaculty;
