import React from "react";
import { Button, Image, Layout, Avatar, Tooltip } from "antd";
import { AiOutlineMenuUnfold, AiOutlineMenuFold } from "react-icons/ai";
import { AiOutlinePoweroff } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { REMOVE_USER } from "../../../../redux/user/actionType";
import instance from "../../../../api/axios";
import { Select } from "antd";
import ReactCountryFlag from "react-country-flag";
import { useState } from "react";
import i18n from "../../../../i18next";
import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import "./header.scss";
import { useTranslation } from "react-i18next";
const { Header } = Layout;
const { Option } = Select;

const CustomHeader = ({ setCollapsed, collapsed }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [lang, setLang] = useState(localStorage.getItem("i18lang"));
  const { t } = useTranslation();
  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const langs = [
    {
      key: "uz",
      label: "O'zbekcha",
    },
    {
      key: "ru",
      label: "Pусский",
    },
  ];

  const onChange = (key) => {
    localStorage.setItem("i18lang", key);
    i18n.changeLanguage(key);
    setLang(key);
  };
  return (
    <Fragment>
      <Header className="site-layout-background" style={{ padding: 0 }}>
        <div className="d-flex justify-content-between me-3">
          <div>
            <Button type="link" onClick={toggle}>
              {collapsed ? (
                <AiOutlineMenuUnfold size={20} />
              ) : (
                <AiOutlineMenuFold size={20} />
              )}
            </Button>
          </div>
          <div className="float-end">
            {/* Languages */}
            <Select
              defaultValue="uz"
              onChange={onChange}
              value={lang}
              style={{
                width: 135,
                color: "white",
                margin: "0",
                padding: "0",
                textAlign: "start",
              }}
              bordered={false}
            >
              {langs?.map((item) => (
                <Option key={item?.key} value={item?.key}>
                  <ReactCountryFlag
                    countryCode={item?.key?.toUpperCase()}
                    svg
                    style={{
                      width: "20px",
                      height: "20px",
                      paddingBottom: "4px",
                      marginRight: "8px",
                    }}
                  />
                  {item?.label}
                </Option>
              ))}
            </Select>
            {/* Logout */}
            <Tooltip
              placement="bottom"
              title={t("Profildan chiqish")}
              color="blue"
            >
              <Button
                onClick={() => {
                  dispatch({ type: REMOVE_USER });
                  localStorage.clear();
                  instance({
                    method: "post",
                    url: "/auth/logout",
                  });
                  Swal.fire({
                    title: "Tizimdan muvoffaqiyatli chiqtingiz!",
                    showConfirmButton: false,
                    icon: "success",
                    background: "#F8F8FB",
                    grow: false,
                    width: "400px",
                    position: "center",
                    timer: 1000,
                    timerProgressBar: true,
                  }).then((r) => {
                    history.push("/login");
                  });
                }}
                type="link"
                icon={<AiOutlinePoweroff />}
                className="text-white"
              ></Button>
            </Tooltip>
          </div>
        </div>
      </Header>
    </Fragment>
  );
};

export default CustomHeader;
