import axios from "axios";

// global
const instance = axios.create({
  baseURL: "https://apiadmission.ftti.uz/api",
});

// local
// const instance = axios.create({
//   baseURL: "http://10.1.1.232:8000/api",
// });

if (localStorage.getItem("access_token"))
  instance.defaults.headers[`Authorization`] =
    "Bearer " + localStorage.getItem("access_token");

export default instance;
