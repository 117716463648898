import React from "react";
import { Fragment } from "react";
import Reports from "../reports/Reports";
import ApplicantUi from "./../applicant/ApplicantUi";
import { useSelector } from "react-redux";

const MainPage = () => {
  const auth = useSelector((state) => state.authRedux);

  return (
    <Fragment>
      {auth?.role?.includes("super-admin") && <Reports />}
      {auth?.role?.includes("receiver") && <Reports />}
      {auth?.role?.includes("receiver_malaka_oshirish") && <Reports />}
      {auth?.role?.includes("applier") && <ApplicantUi />}
    </Fragment>
  );
};

export default MainPage;
